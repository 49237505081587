import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import VueMeta from 'vue-meta'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import { auth, db, firebase } from '@/firebase'

Vue.use(VueRouter)
Vue.use(VueMeta)
// console.log(getUserData().teams ? (getUserData().teams ? (getUserData().teams > 1 ? 1 : 2) : 3) : 4);
// localStorage.setItem('userData', JSON.stringify({ teams: 0 }))
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: 'auth-login' } },
    // { path: '/', redirect: { name: 'sites-no-project' } },
    getUserData() && getUserData().teams ? (getUserData().teams ? (getUserData().teams > 1 ? { path: '/', redirect: { name: 'projects' } } : { path: '/', redirect: { name: 'sites-no-project' } }) : { path: '/', redirect: { name: 'projects' } }) : { path: '/', redirect: { name: 'projects' } },
    // { path: '/access-control', redirect: { name: 'access-control' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const user = auth.currentUser
  // console.log(to.meta.title)
  // window.document.title = to.meta && to.meta.titlex ? to.meta.title : 'Home';

  if (!canNavigate(to)) {
    if(!user) {
      const crypt = (salt, text) => {
        const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
        const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
      
        return text
          .split("")
          .map(textToChars)
          .map(applySaltToChar)
          .map(byteHex)
          .join("");
      };
      
      var urlConvert = new URL(window.location.href)
      // console.log(urlConvert.pathname);
      // var pathname = decrypt("salt", urlConvert.pathname)
      
      setTimeout(() => {
        history.pushState(null, "", `?redirect=${crypt("salt", (urlConvert.pathname.includes('/share') ? '/' :  urlConvert.pathname))}`)
      }, 300);

      return next({ name: 'auth-login' })
    } else {
      return next({ name: 'misc-not-authorized' })
    }

    // Redirect to login if not logged in
    // if (!user) return next({ name: 'auth-login' })

    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  // if (user) {
  //   const canAccess = true

  //   if (canAccess) {
  //     const urlConvert = new URL(window.location.href)
  //     const params = new URLSearchParams(urlConvert.search)
  //     const redirectValue = params ? params.get('redirect') : ''
  //     if(redirectValue) {
  //       const decrypt = (salt, encoded) => {
  //         const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  //         const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  //         return encoded
  //           .match(/.{1,2}/g)
  //           .map((hex) => parseInt(hex, 16))
  //           .map(applySaltToChar)
  //           .map((charCode) => String.fromCharCode(charCode))
  //           .join("");
  //       };

  //       var pathname = decrypt("salt", redirectValue)
  //       var urlConcat = urlConvert.protocol + '//' + urlConvert.host + pathname
  //       window.location.href = urlConcat
  //     } else {
  //       return next()
  //     }
  //   }
  //   next({ name: 'misc-not-authorized' })
  // } else {
  //   return next()
  // }

  if (user) {
    const canAccess = true;
  
    if (canAccess) {
      const urlConvert = new URL(window.location.href);
      const params = new URLSearchParams(urlConvert.search);
      const redirectValue = params.get('redirect') || '';
  
      if (redirectValue) {
        const decrypt = (salt, encoded) => {
          const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
          const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
          
          return encoded
            .match(/.{1,2}/g)
            .map((hex) => parseInt(hex, 16))
            .map(applySaltToChar)
            .map((charCode) => String.fromCharCode(charCode))
            .join("");
        };
  
        try {
          var pathname = decrypt("salt", redirectValue);
          var urlConcat = urlConvert.protocol + '//' + urlConvert.host + pathname;
  
          // Redirige al usuario a la URL decodificada
          window.location.href = urlConcat;
        } catch (error) {
          console.error("Error al decodificar la URL:", error);
          // Si ocurre un error en la decodificación, envía al usuario a una página de error o la página por defecto
          return next({ name: 'misc-not-authorized' });
        }
      } else {
        return next();
      }
    } else {
      return next({ name: 'misc-not-authorized' });
    }
  } else {
    return next();
  }

  // if(user) {
  //   db.collection('Users').doc(user.uid).get()
  //   .then(userDoc => {
  //     var canAccess = true
  //     if(to.meta.access) {
  //       canAccess = false
  //       if(to.meta.access.includes('manage') && userDoc.data().role === 'admin') {
  //         canAccess = true
  //       } else if(to.meta.access.includes('manageAndStaff') && userDoc.data().role === 'staff') {
  //         // if staff member has permissions of clinician viewer
  //         if(to.meta.access.includes('manageAndStaffViewer')) {
  //           if(userDoc.data().permissions.includes('clinician viewer')) {
  //             canAccess = true
  //           }
  //         }

  //         // if staff member has permissions of data specialist
  //         if(to.meta.access.includes('manageAndStaffDataS')) {
  //           if(userDoc.data().permissions.includes('data specialist')) {
  //             canAccess = true
  //           }
  //         }

  //         // if staff member has permissions of manage users
  //         if(to.meta.access.includes('manageAndStaffManage')) {
  //           if(userDoc.data().permissions.includes('manage users')) {
  //             canAccess = true
  //           }
  //         }
  //       }
  //     }
  //     // console.log(to.meta.access,canAccess)

  //     if(canAccess) {
  //       return next()
  //     } else {
  //       return next({ name: 'misc-not-authorized' })
  //     }
  //   })
  //   // return next()
  // } else {
  //   return next()
  // }
})

// if(userDoc.data().role === 'admin') {

// } else if(userDoc.data().role === 'staff') {
//     if(userDoc.data().permissions.includes('manage users')) {
//     } else if(userDoc.data().permissions.includes('data specialist')) {
//     } else if(userDoc.data().permissions.includes('clinician viewer')) {

// } else if(userDoc.data().role === 'clinician') {

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
const DEFAULT_TITLE = 'SEOcloud'
router.afterEach(to => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  Vue.nextTick(() => {
    // console.log(to.meta)
    // document.title = to.meta.title
    document.title = to.meta.title || DEFAULT_TITLE
    // document.meta.title = to.meta.title || DEFAULT_TITLE;
  })
})

export default router
