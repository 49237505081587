export default [
  // *===============================================---*
  // *--------- Sites -------------------------------------------*
  // *===============================================---*
  {
    path: '/my-sites/archive',
    name: 'archive-sites',
    component: () => import('@/views/extensions/acl/table/ArchiveSites.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage', 'manageAndStaff', 'manageAndStaffDataS', 'manageAndStaffManage'],
      pageTitle: 'Archived sites',
      title: 'Archived sites - SEOcloud',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/my-sites/new-site',
    name: 'new-site',
    component: () => import('@/views/apps/sites/sites-add/AddSite.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage', 'manageAndStaff', 'manageAndStaffDataS', 'manageAndStaffManage'],
      pageTitle: 'Create New site',
      title: 'Create New site - SEOcloud',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/my-sites/edit-site/:id/:project',
    name: 'edit-site',
    component: () => import('@/views/apps/sites/sites-edit/EditSite.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage', 'manageAndStaff', 'manageAndStaffDataS', 'manageAndStaffManage'],
      pageTitle: 'Edit site',
      title: 'Edit site - SEOcloud',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/my-sites/view-errors/:id/:project',
    name: 'view-errors',
    component: () => import('@/views/apps/sites/sites-edit/ViewErrors.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage', 'manageAndStaff', 'manageAndStaffDataS', 'manageAndStaffManage'],
      pageTitle: 'View errors',
      // title: 'View errors - SEOcloud',
      contentClass: 'ecommerce-application',
    },
  },
  // *===============================================---*
  // *--------- Projects -------------------------------------------*
  // *===============================================---*
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/apps/projects/projects-show/BelongsProjects.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage', 'manageAndStaff', 'manageAndStaffDataS', 'manageAndStaffManage'],
      pageTitle: 'Projects',
      title: 'Projects - SEOcloud',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/my-sites/new-project',
    name: 'new-project',
    component: () => import('@/views/apps/projects/projects-add/AddProject.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage', 'manageAndStaff', 'manageAndStaffDataS', 'manageAndStaffManage'],
      pageTitle: 'Create new project',
      title: 'Create new project - SEOcloud',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/my-sites/sites-no-project/:id',
    name: 'sites-no-project',
    component: () => import('@/views/apps/projects/projects-show/NoProject.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage', 'manageAndStaff', 'manageAndStaffDataS', 'manageAndStaffManage'],
      pageTitle: 'My sites',
      title: 'My sites - SEOcloud',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/my-sites/project-sites/:id',
    name: 'project-sites',
    component: () => import('@/views/apps/projects/projects-show/ShowProject.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage', 'manageAndStaff', 'manageAndStaffDataS', 'manageAndStaffManage'],
      // pageTitle: ':txt',
      // title: ':txt',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/my-sites/edit-project-list/:id',
    name: 'edit-project',
    component: () => import('@/views/apps/projects/projects-edit/EditProject.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage', 'manageAndStaff', 'manageAndStaffDataS', 'manageAndStaffManage'],
      pageTitle: 'Edit project',
      title: 'Edit project - SEOcloud',
      contentClass: 'ecommerce-application',
    },
  },
  // *===============================================---*
  // *--------- Licenses -------------------------------------------*
  // *===============================================---*
  {
    path: '/licenses',
    name: 'memberships',
    component: () => import('@/views/apps/memberships/Memberships.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage', 'manageAndStaff', 'manageAndStaffDataS', 'manageAndStaffManage'],
      pageTitle: 'Licenses',
      title: 'Licenses - SEOcloud',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/licenses/paid',
    name: 'memberships-paid',
    component: () => import('@/views/apps/memberships/Memberships.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage', 'manageAndStaff', 'manageAndStaffDataS', 'manageAndStaffManage'],
      pageTitle: 'Licenses',
      title: 'Licenses - SEOcloud',
      contentClass: 'ecommerce-application',
    },
  },
  // {
  //   path: '/success-payment',
  //   name: 'success-payment',
  //   component: () => import('@/views/apps/memberships/SuccessPayment.vue'),
  //   meta: {
  //     resource: 'ACL',
  //     action: 'read',
  //     access: ['manage', 'manageAndStaff', 'manageAndStaffDataS', 'manageAndStaffManage'],
  //     pageTitle: 'Success Payment',
  //     title: 'Success Payment - SEOcloud',
  //     contentClass: 'ecommerce-application',
  //   },
  // },
  // {
  //   path: '/cancel-payment',
  //   name: 'cancel-payment',
  //   component: () => import('@/views/apps/memberships/CancelPayment.vue'),
  //   meta: {
  //     resource: 'ACL',
  //     action: 'read',
  //     access: ['manage', 'manageAndStaff', 'manageAndStaffDataS', 'manageAndStaffManage'],
  //     pageTitle: 'Cancel Payment',
  //     title: 'Cancel Payment - SEOcloud',
  //     contentClass: 'ecommerce-application',
  //   },
  // },
]
