import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import moment from 'moment'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import {
  db, auth, storage, firebase, secondaryApp,
} from '../firebase'
import router from '../router'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cargaAction: false,
    usuario: '',
  },
  mutations: {
    setCargaAction(state, payload) {
      state.cargaAction = payload
    },
    nuevoUsuario(state, payload) {
      if (payload) {
        state.usuario = payload
      } else {
        state.usuario = ''
      }
    },
  },
  actions: {
    // Configure the logged in user
    setUsuario({ commit }, user) {
      // if(user) {
        db.collection('Users').doc(user.uid)
        .onSnapshot(userDoc => {
        // .get().then(userDoc => {
          if(userDoc.exists) {
            // console.log(userDoc.data().teams)
            const usuario = {
              nombre: userDoc.data().nombre ? userDoc.data().nombre : '',
              email: userDoc.data().email,
              uid: userDoc.data().uid,
              foto: userDoc.data().foto,
              role: userDoc.data().role,
              ability: userDoc.data().ability,
            }

            const user = firebase.auth().currentUser

            user.updateProfile({
              displayName: userDoc.data().nombre ? userDoc.data().nombre : '',
            })
            // .then(() => {
            //   console.log('display name inserted')
            // })

            localStorage.setItem('userData', JSON.stringify({ teams: !userDoc.data().teams ? 1 : userDoc.data().teams ? userDoc.data().teams.length : 0 }))
            localStorage.setItem('usAb', JSON.stringify({ ability: userDoc.data().ability }))
            commit('nuevoUsuario', usuario)
          }
        })
        // }, error => console.log(error))
      // } else {
      //   auth.signOut()
      //   commit('nuevoUsuario',null)
      //   localStorage.removeItem('usAb')
      //   localStorage.removeItem('logActy')
      //   window.location.href = '/login'
      // }
    },
    // register usuario
    crearUsuario({ commit }, usuario) {
      auth.createUserWithEmailAndPassword(usuario.email, usuario.password)
      .then(res => {
        const { uid } = res.user

        const usuarioObject = {
          nombre: usuario.username,
          email: usuario.email,
          uid,
          foto: '',
          role: 'user',
          status: 'active',
          typeRegister: 'Regular register page',
          totalSites: 10,
          teams: [],
          ability: [
            {
              action: 'manage',
              subject: 'all',
            },
          ],
        }

        // verificar si el usuario ya tiene documento
        db.collection('Users').where('email', '==', usuarioObject.email).get()
        .then(itemUser => {
          // el correo ya existe
          if(itemUser.size >= 1) {
            this._vm.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Try with other email',
                icon: 'InfoIcon',
                variant: 'warning',
                text: 'The email with which you want to register cannot be added, because it already exists',
              },
            })
          } else {
              db.collection('Users').doc(uid).set(usuarioObject)
              .then(() => {
                localStorage.setItem('userData', JSON.stringify({ teams: 1 }))

                // register my sites
                db.collection('Projects').add({
                  name: 'My sites',
                  color: '#d1d1d1',
                  isMySites: true,
                  numberSites: 0,
                  numberTeamMembers: 0,
                  owner: {
                    nombre: usuario.username,
                    email: usuario.email,
                    uid,
                    foto: '',
                  },
                  teamMembers: []
                })
                .then(docRef => {
                  // update the uid of the project
                  db.collection('Projects').doc(docRef.id).update({
                    id: docRef.id,
                  })
                  .catch(error => {
                    console.error(error)
                  })
          
                  db.collection('Users').doc(uid).collection('projects').doc(docRef.id).set({
                    id: docRef.id,
                    name: 'My sites',
                    color: '#d1d1d1',
                    isMySites: true,
                    numberSites: 0,
                    // sites: site ? site : '',
                    numberTeamMembers: 0,
                    owner: {
                      nombre: usuario.username,
                      email: usuario.email,
                      uid,
                      foto: '',
                    },
                    teamMembers: []
                  })
                  .catch(error => {
                    console.error(error)
                  })
                

                  var objWelcomeMail = {
                    nameUser: usuario.username ? usuario.username : usuario.email,
                    email: usuario.email
                  }
                  
                  // update teams owner
                  db.collection('Users').doc(uid).update({
                    teams: firebase.firestore.FieldValue.arrayUnion(docRef.id),
                    updated: Date.now(),
                    mySitesId: docRef.id
                  })
                  .then(() => {
                    // update seocloud-dcdfb
                    const config = {
                      url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/welcomeMail',
                      method: 'POST',
                      // timeout: 0,
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      data: JSON.stringify(objWelcomeMail),
                    }
              
                    this._vm.axios(config)
                    .then(() => {
                    // console.log(response.data)
                      console.log('email sended')
                    })
                    .catch(e => {
                      console.log(e)
                    })

                    this._vm.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'User successfully registered',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                      },
                    })

                    this._vm.$ability.update(usuarioObject.ability)
                    commit('nuevoUsuario', usuarioObject)
                    // console.log(usuario.idsite)
                    // usuario.idsite ? null : router.replace(getHomeRouteForLoggedInUser('staff'))
                    router.replace(getHomeRouteForLoggedInUser('staff'))
                  })
                  .catch(error => {
                    console.error(error)
                  })
                })
              })
              .catch(e => console.log(e))
            // }
          }
        })
        .catch(error => {
          console.log(error)
        })
      })
      .catch(error => {
        if (error.code === 'auth/weak-password') {
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: 'The password cannot be less than 6 characters',
            },
          })
        } else if (error.code === 'auth/email-already-in-use') {
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: 'The email is already taken',
            },
          })
        } else {
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: 'Check that you have entered the data correctly',
            },
          })
        }
      })
    },
    async agregarUsuarios({ commit }, payload) {
      // agregar usuario por medio de otro usuario
      const makeid = length => {
        let result = ''
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        const charactersLength = characters.length
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength))
        }
        return result
      }

      try {
        const pass = payload.password ? payload.password : makeid(8)
        const signInEmail = await secondaryApp.auth().createUserWithEmailAndPassword(payload.email, pass)

        const userObject = {
          nombre: payload.nombre,
          firstName: payload.firstName ? payload.firstName : '',
          lastName: payload.lastName ? payload.lastName : '',
          email: payload.email,
          uid: signInEmail.user.uid,
          foto: '',
          role: payload.role,
          contact: payload.contact ? payload.contact : '',
          permissions: payload.permissions,
          totalSites: 10,
          status: 'active',
          ability: [
            {
              action: 'manage',
              subject: 'all',
            },
          ],
        }

        db.collection('Users').doc(signInEmail.user.uid).set(userObject)
          .then(() => {
            this._vm.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `User ${payload.nombre} created`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'User created successfully!',
              },
            })
            auth.sendPasswordResetEmail(payload.email)

            if (payload.role !== 'admin') {
            // var obj = {
            //   email: payload.email,
            // };

              // var config = {
              //   method: 'POST',
              //   url: 'https://us-central1-nycpg-69e96.cloudfunctions.net/invitationMail',
              //   headers: {
              //     'Content-Type': 'application/json'
              //   },
              //   data : JSON.stringify(obj),
              // };

            // this._vm.axios(config)
            // .then(() => {
            //   this._vm.$toast({
            //     component: ToastificationContent,
            //     position: 'top-right',
            //     props: {
            //       title: `Invitation has been sent`,
            //       icon: 'CoffeeIcon',
            //       variant: 'success',
            //       text: `The invitation has been sent to ${payload.email}`,
            //     },
            //   })
            // })
            // .catch(function (error) {
            //   console.log(error);
            // });
            }
          })
          .catch(error => {
            console.log(error)
          })
      } catch (error) {
        // El Usuario ya existe
        if (error.code === 'auth/email-already-in-use') {
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Try other email',
              icon: 'InfoIcon',
              variant: 'warning',
              text: 'The email you want to register with cannot be added, because it already exists',
            },
          })
        } else {
          console.log(error)
        }
      }
    },
    // edit user data through another user
    editarUsuario({ commit }, payload) {
      db.collection('Users').doc(payload.id).update({
        nombre: `${payload.firstName} ${payload.lastName}`,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        contact: payload.contact,
        permissions: payload.permissions,
      })
        .then(() => {
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'User edited successfully',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    // edit user from profile settings
    async editarGeneralUsuario({ commit }, payload) {
      try {
        // console.log(payload)
        const dataToUpdate = {}
        const imagenes = []
        if (payload.fileImg) {
          const objImgs = {}
          const refImg = await storage.ref().child('users').child(payload.id).child(payload.fileImg.name)
          const res = await refImg.put(payload.fileImg)
          const urlDescarga = await refImg.getDownloadURL()
          objImgs.imagen = urlDescarga
          objImgs.mimeType = payload.fileImg.type
          objImgs.size = payload.fileImg.size
          objImgs.lastModified = payload.fileImg.lastModified
          objImgs.name = payload.fileImg.name
          imagenes.push(objImgs)
          dataToUpdate.foto = urlDescarga
        }
        dataToUpdate.nombre = payload.nombre ? payload.nombre : ''
        dataToUpdate.contact = payload.contact ? payload.contact : ''
        dataToUpdate.address1 = payload.address1 ? payload.address1 : ''
        dataToUpdate.address2 = payload.address2 ? payload.address2 : ''
        dataToUpdate.company = payload.company ? payload.company : ''
        dataToUpdate.country = payload.country ? payload.country : ''
        dataToUpdate.stateOrRegion = payload.stateOrRegion ? payload.stateOrRegion : ''
        dataToUpdate.city = payload.city ? payload.city : ''
        dataToUpdate.zipCode = payload.zipCode ? payload.zipCode : ''
        dataToUpdate.uid = payload.id
        
        db.collection('Users').doc(payload.id).get()
        .then(user => {
          dataToUpdate.foto = dataToUpdate.foto ? dataToUpdate.foto : user.data().foto ? user.data().foto : '',
          // update owner projects
          // db.collection('Users').doc(payload.id).get()
          // .then(userDoc => {
            // users.forEach(userDoc => {
            db.collection('projects').where('owned.uid', '==', payload.id).get()
            .then(projectQuery => {
              if(projectQuery.size >= 1) {
                projectQuery.forEach(project => {
                  db.collection('projects').doc(project.id).update({
                    owned: dataToUpdate
                  })
                  // .then(() => console.log('updated'))
                })
              }
            })
            // })
          // })

          // update owner site
          // db.collection('Users').doc(payload.id).get()
          // .then(userDoc => {
            // users.forEach(userDoc => {
            db.collection('sites').where('owned.uid', '==', payload.id).get()
            .then(siteQuery => {
              if(siteQuery.size >= 1) {
                siteQuery.forEach(site => {
                  db.collection('sites').doc(site.id).update({
                    owned: dataToUpdate
                  })
                  // .then(() => console.log('updated'))
                })
              }
            })
            // })
          // })

          // update data users in projects
          // db.collection('Users').doc(payload.id).get()
          // .then(user => {
            // projects.forEach(project => {
            if(user.data().teams && user.data().teams.length >= 1) {
              user.data().teams.forEach(team => {
                db.collection('projects').doc(team).collection('members').where('uid', '==', payload.id).get()
                .then(invitationsProject => {
                  if(invitationsProject.size >= 1) {
                    invitationsProject.forEach(invitation => {
                      if(invitation.data().uid) {
                        // db.collection('Users').doc(payload.id).get()
                        // .then(user => {
                          db.collection('projects').doc(team).collection('members').doc(invitation.id).update({
                            imgU: dataToUpdate.foto ? dataToUpdate.foto : user.data().foto ? user.data().foto : '',
                            name: dataToUpdate.nombre
                          })
                        // })
                      }
                    })
                  }
                })
              })
            }
            // })
          // })
        })
        // dataToUpdate.nombre = payload.firstName + ' ' + payload.lastName
        // dataToUpdate.firstName = payload.firstName
        // dataToUpdate.lastName = payload.lastName
        // dataToUpdate.email = payload.email

        // if(payload.contact) {
        //   dataToUpdate.contact = payload.contact
        // } else {
        //   dataToUpdate.contact = ""
        // }

        // dataToUpdate.nombre =  payload.nombre ? payload.nombre : ''
        // dataToUpdate.contact =  payload.contact ? payload.contact : ''
        // dataToUpdate.address1 =  payload.address1 ? payload.address1 : ''
        // dataToUpdate.address2 =  payload.address2 ? payload.address2 : ''
        // dataToUpdate.company =  payload.company ? payload.company : ''
        // dataToUpdate.country =  payload.country ? payload.country : ''
        // dataToUpdate.stateOrRegion =  payload.stateOrRegion ? payload.stateOrRegion : ''
        // dataToUpdate.city =  payload.city ? payload.city : ''
        // dataToUpdate.zipCode =  payload.zipCode ? payload.zipCode : ''
        // console.log(dataToUpdate)
        db.collection('Users').doc(payload.id).update(dataToUpdate)
          .then(() => {
            this._vm.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'User updated',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'User updated successfully',
              },
            })
          })
          .catch(error => console.log(error))
      } catch (error) {
        console.log(error)
      }
    },
    // create project
    createProject({ commit }, payload) {
      const site = payload.projectSites.trim()
      
      if(payload.projectInvitations.length >= 1) {
        payload.projectInvitations.forEach(ele => {
          if(ele.permission.title) {
            ele.permission.title === 'Viewer' ? ele.permission = 'view' : ele.permission.title === 'Editor' ? ele.permission = 'edit' : ele.permission.title === 'Admin/editor' ? ele.permission = 'admin' : ele.permission = 'view'
          } else {
            ele.permission === 'Viewer' ? ele.permission = 'view' : ele.permission === 'Editor' ? ele.permission = 'edit' : ele.permission === 'Admin/editor' ? ele.permission = 'admin' : ele.permission = 'view'
          }
        })
      }

      db.collection('Projects').add({
        name: payload.projectName ? payload.projectName : '',
        color: payload.projectColor,
        isMySites: false,
        numberSites: 0,
        // sites: site ? site : '',
        numberTeamMembers: payload.projectInvitations.length,
        owner: payload.owned ? payload.owned : '',
        teamMembers: []
      })
      .then(docRef => {
        // update the uid of the project
        db.collection('Projects').doc(docRef.id).update({
          id: docRef.id,
        })
        .catch(error => {
          console.error(error)
        })

        db.collection('Users').doc(payload.owned.uid).get()
        .then(docUser => {
          var objnewProjectMail = {
            nameUser: docUser.data().nombre ? docUser.data().nombre : docUser.data().email,
            email: docUser.data().email,
            existUser: true,
            projectName: payload.projectName ? payload.projectName : '',
            projectId: docRef.id
          }

          // update seocloud-dcdfb
          var confignewProjectMail = {
            method: 'POST',
            url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/newProjectMail',
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify(objnewProjectMail),
          }

          this._vm.axios(confignewProjectMail)
          .then(() => {
            console.log('email sended')
          })
          .catch(error => {
            console.log(error)
          })
        })

        // create project details
        // db.collection('project-details').doc(docRef.id).set({
        //   id: docRef.id,
        //   name: payload.projectName ? payload.projectName : '',
        //   color: payload.projectColor,
        //   isMySites: false,
        //   numberSites: 0,
        //   // sites: site ? site : '',
        //   numberTeamMembers: payload.projectInvitations.length,
        //   owner: payload.owned ? payload.owned : '',
        //   teamMembers: []
        // })
        // .catch(error => {
        //   console.error(error)
        // })

        // update teams owner
        db.collection('Users').doc(payload.owned.uid).update({
          teams: firebase.firestore.FieldValue.arrayUnion(docRef.id),
          updated: Date.now()
        })
        .catch(error => {
          console.error(error)
        })

        db.collection('Users').doc(payload.owned.uid).collection('projects').doc(docRef.id).set({
          id: docRef.id,
          name: payload.projectName ? payload.projectName : '',
          color: payload.projectColor,
          isMySites: false,
          numberSites: 0,
          // sites: site ? site : '',
          numberTeamMembers: payload.projectInvitations.length,
          owner: payload.owned ? payload.owned : '',
          teamMembers: []
        })
        .catch(error => {
          console.error(error)
        })

        // validation if add site
        if(site) {
          // get domain URL
          var url = site
          var URLactual = new URL(url)
          var URLdomain = URLactual.host
          var URLpathname = URLactual.pathname
          var URLprotocol = URLactual.protocol
          var httpsString = URLprotocol + '//'

          if(URLpathname === '/') {
            var urlBody = httpsString + URLdomain
            var urlBodyx = httpsString + URLdomain
            var URLwww = ''
            var URLwithoutWWW = ''
            var urlPrefix = 'www'
            if(URLdomain.includes('www.')) {
              urlPrefix = 'www'
              URLwww = URLdomain
              URLwithoutWWW = URLdomain.slice(4)
            } else {
              urlPrefix = 'non-www'
              URLwww = 'www.'+URLdomain
              URLwithoutWWW = URLdomain
            }

            // QUERY EXIST SITE
            db.collection('Sites').where('WebsiteURL', 'in', [URLdomain,URLwww,URLwithoutWWW]).get()
            .then((querySiteThumb) => {
              var existThumbnail = ''
              var idSite =  ''
              if(querySiteThumb.size) {
                querySiteThumb.forEach(itemSite => {
                  idSite = itemSite.id
                  if(itemSite.data().thumbnail) {
                    existThumbnail = itemSite.data().thumbnail
                  }
                })
              }

              db.collection('Sites').where('WebsiteURL', 'in', [URLdomain,URLwww,URLwithoutWWW]).where('createdBy', '==', payload.owned.uid).get()
              .then((querySite) => {
                if(!querySite.size) {
                  if(querySiteThumb.size) {
                    db.collection('Sites').doc(idSite).update({
                      teamMembers: firebase.firestore.FieldValue.arrayUnion(payload.owned.uid),
                      projects: firebase.firestore.FieldValue.arrayUnion(docRef.id)
                    })
                    .catch(e => console.log('Error update ref site: ', e))

                    var dataSrc = JSON.stringify({
                      "website": urlBodyx
                    });
              
                    var configSrc = {
                      method: 'post',
                      maxBodyLength: Infinity,
                      url: 'https://sslcheckerapi-h4klmyt43a-uc.a.run.app/screenshots/register',
                      headers: { 
                        'Content-Type': 'application/json'
                      },
                      data : dataSrc
                    };
              
                    this._vm.axios(configSrc)
                    .then((response) => {
                      console.log('register screenshots: ', response)
                    })
                    .catch((error) => {
                      console.error('Error register screenshot: ', error)
                    });
                    
                    // add site to project
                    db.collection('Projects').doc(docRef.id).collection('sites').doc(idSite).set({
                      'SiteID': idSite,
                      'WebsiteURL': URLdomain,
                      'homepage': urlBodyx,
                      'name': payload.siteName,
                      'n': payload.siteName ? payload.siteName.toLowerCase() : '',
                      thumbnail: existThumbnail,
                      'totalErrors': 0,
                      'plan': '',
                      'paidRecurrence': '',
                      'claimed': false,
                      'owner': '',
                      'status': 'active',
                      // projectId: docRef.id,
                    })
                    .catch(error => {
                      console.error(error)
                    })

                    if(idSite) {
                      var body = {
                        url: '',
                        type: 'full',
                        forceNotFound: 'yes',
                        pageSize: 999999999,
                        id: 'seo-shops-full-' + Date.now(),
                        takeScreenshots: 'no',
                        source: 'seo-cloud-full-' + Date.now(),
                        triggeredBy: 'test triggered',
                        screenshotType: 'test',
                        screenshotComment: '',
                        stringSearch: ["PHP ERROR",'Lorem','Ipsum'],
                        ignoreIndexNoFollow: "yes"
                      }
                      var getUrl = new URL(site);
                      var hostUrl = getUrl.host;
                      var httpsString = getUrl.protocol + '//';
                      var urlConcat = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                      var urlBody = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                      var ultimoCaracterUrl = urlConcat.charAt(urlConcat.length - 1);
                      if(ultimoCaracterUrl != "/") {
                        urlConcat += "/";
                      }
        
                      body.id = 'seo-shops-full-' + Date.now()
                      body.source = 'seo-shops-full-' + Date.now()
                      body.url = getUrl.protocol + "//" + hostUrl
        
                      if(getUrl.pathname === "/") {
                        body.takeScreenshots = 'no'
                        body.screenshotComment = ""
                      }
        
                      db.collection('Sites').doc(idSite).get()
                      .then(eleSite => {
                        // exist site
                        // db.collection('Crawls').where('status', '==', 'running').get()
                        // .then(queryCrawlRunning => {
                          // if(querySites.) {
                          var countSites = 0
                          var pageSizes = eleSite.data().pageSizes && eleSite.data().pageSizes.length ? eleSite.data().pageSizes : []
                          var pagesSite = eleSite.data().pages && eleSite.data().pages.length ? eleSite.data().pages : []
                          var filterInternals = pagesSite.filter(ele => ele.isSitemap === true)
                          var filterNoInternals = pagesSite.filter(ele => ele.isSitemap === false)
                          var filterProPages = filterNoInternals.filter(ele => ele.pro === true)
                          var filterNoProPages = filterNoInternals.filter(ele => ele.pro === false)
                          var filterInlinks = filterNoProPages
        
                          var condicionParaOrdenar = (pageA, pageB) => {
                            return pageB.inlinks.length - pageA.inlinks.length;
                          }
                          filterInlinks.sort(condicionParaOrdenar)
        
                          var arrayFoundPages = []
                          var arrayProPages = []
                          filterProPages.forEach(ele => {
                            var getUrl = new URL(ele.url)
                            if(getUrl.pathname !== '/') {
                              arrayProPages.push(ele.url)
                            }
        
                            arrayFoundPages.unshift(ele.url)
                          })
        
                          // filterInternals.forEach(ele => {
                          //   var filterPage = arrayFoundPages.filter(item => item === ele.url)
                          //   if(!filterPage.length) {
                          //     arrayFoundPages.push(ele.url)
                          //   }
                          // })
                          
                            db.collection('Crawls').where('WebsiteURL', '==', hostUrl).where('status', '==', 'running').get()
                            .then(queryCrawl => {
                              // only 1 loop
                              if(countSites === 0 && !queryCrawl.size) {
                                countSites += 1
                                var stringsArray = eleSite.data().stringSearch ? eleSite.data().stringSearch : []
                                var suspiciousTextArray = eleSite.data().suspiciousText ? eleSite.data().suspiciousText : []
                                var arrayStringsSuspicious = []
                                stringsArray.forEach(ele => {
                                  arrayStringsSuspicious.push(ele)
                                })

                                suspiciousTextArray.forEach(ele => {
                                  arrayStringsSuspicious.push(ele)
                                })

                                var getConfigCrawl = localStorage.getItem('configCrawl')
                                var limitCrawl = 100
                                var configCrawlType = 'JS FREE'
                                var configCrawlPages = 'FREE'
                                var setCrawl = false
                                if(getConfigCrawl) {
                                  setCrawl = true
                                  var parseConfig = JSON.parse(getConfigCrawl)
                                  var logicTime = moment(parseConfig.date)
                                  var actualTime = moment(new Date())
                                  var diffSeconds = actualTime.diff(logicTime, 'seconds');
                                  if(diffSeconds < 600) {
                                    limitCrawl = parseConfig.pages
                                    configCrawlType = parseConfig.crawl
                                    configCrawlPages = parseConfig.pages === 25 ? 'GUESS' : parseConfig.pages === 100 ? 'FREE' : parseConfig.pages === 600 ? 'LICENSE' : 'FREE'
                                  }
                                  var getConfigCrawl = localStorage.removeItem('configCrawl')
                                }
                                
                                var limitCrawlSetSite = eleSite.data().limitCrawl ? eleSite.data().limitCrawl : 0
                                if(limitCrawlSetSite) {
                                  if(configCrawlPages === 'GUESS' && limitCrawlSetSite < 25) {
                                    limitCrawl = limitCrawlSetSite
                                  } else if(configCrawlPages === 'FREE' && limitCrawlSetSite < 100) {
                                    limitCrawl = limitCrawlSetSite
                                  } else if(configCrawlPages === 'LICENSE') {
                                    limitCrawl = limitCrawlSetSite
                                  }
                                }
        
                                // add Crawl
                                db.collection('Crawls').add({
                                  'IDcrawl': body.id,
                                  'dateStart': Date.now(),
                                  'dateStartFormat': new Date(),
                                  'dateEnd': null,
                                  'crawlDuration': null,
                                  'status': 'running',
                                  'crawltype': 'full',
                                  'ignoreindexnofollow': 'yes',
                                  'Requests': 1,
                                  'SiteID': eleSite.id,
                                  'WebsiteURL': hostUrl,
                                  'blockedByRobots': false,
                                  'logicRequests': 1,
                                  'proPages': [urlBody],
                                  'totalErrors': 0,
                                  'limitCrawl': limitCrawl,
                                  'stringSearch': arrayStringsSuspicious,
                                  'founds': arrayFoundPages,
                                  'requests': [],
                                  'pages': [],
                                  'inlinks': [],
                                  'external': [],
                                  'pageSizes': pageSizes,
                                  'updated': Date.now(),
                                  'plus1k': false,
                                  'errorsPages': [],
                                  'configCrawlType': 'JS FREE',
                                  'configCrawlPages': 'FREE',
                                  'errorsTypePage': [],
                                  'timeoutPages': 0,
                                  'errorsCategory': {}
                                })
                                .then((docRefCrawl) => {
                                  var URLsfound = [
                                    {
                                      'id': null,
                                      'title': null,
                                      'runPosition': 1,
                                      'lastRequest': null,
                                      'pro': true,
                                      'inlinks': [],
                                      'status': null,
                                      'redirectTo': null,
                                      'blockedByRobots': false,
                                      // 'totalErrors': 0,
        
                                      'crawleable': true,
                                      'crawled': false,
                                      'crawlerInvoke': true,
                                      'url': urlBody
                                      
                                    },
                                    // {
                                    //   'id': null,
                                    //   'title': null,
                                    //   'runPosition': 2,
                                    //   'lastRequest': null,
                                    //   'pro': false,
                                    //   'inlinks': [],
                                    //   'status': null,
                                    //   'redirectTo': null,
                                    //   'blockedByRobots': false,
        
                                    //   'crawleable': true,
                                    //   'crawled': false,
                                    //   'crawlerInvoke': true,
                                    //   'url': urlConcat + "site-map.xml"
                                    // },
                                    {
                                      'id': null,
                                      'title': null,
                                      'runPosition': 2,
                                      'lastRequest': null,
                                      'pro': false,
                                      'inlinks': [],
                                      'status': null,
                                      'redirectTo': null,
                                      'blockedByRobots': false,
        
                                      'crawleable': true,
                                      'crawled': false,
                                      'crawlerInvoke': true,
                                      'url': urlConcat + "sitemap.xml"
                                    },
                                    // {
                                    //   'id': null,
                                    //   'title': null,
                                    //   'runPosition': 4,
                                    //   'lastRequest': null,
                                    //   'pro': false,
                                    //   'inlinks': [],
                                    //   'status': null,
                                    //   'redirectTo': null,
                                    //   'blockedByRobots': false,
        
                                    //   'crawleable': true,
                                    //   'crawled': false,
                                    //   'crawlerInvoke': true,
                                    //   'url': urlConcat + "page-sitemap.xml"
                                    // }
                                  ]

                                  db.collection('Sites').doc(eleSite.id).update({
                                    mostRecentCrawl: docRefCrawl.id,
                                    n_log_crawls: eleSite.data().n_log_crawls ? firebase.firestore.FieldValue.increment(1) : 1,
                                    totalErrors: 0
                                  })
        
                                  // add pages crawl
                                  URLsfound.forEach(eleC => {
                                    db.collection('Crawls').doc(docRefCrawl.id).update({
                                      requests: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                    })
        
                                    db.collection('Crawls').doc(docRefCrawl.id).update({
                                      founds: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                    })
        
                                    db.collection('Crawls').doc(docRefCrawl.id).update({
                                      pages: firebase.firestore.FieldValue.arrayUnion({
                                        id: '',
                                        title: '',
                                        runPosition: null,
                                        lastRequest: null,
                                        pro: false,
                                        inlinks: [],
                                        status: null,
                                        redirectTo: '',
                                        blockedByRobots: false,
                                        crawled: true,
                                        crawleable: true,
                                        url: eleC.url,
                                        isSitemap: false,
                                        hasRun: false,
                                        active: false,
                                        size: 999999999,
                                        tries: 1,
                                        tryTimestamp: Date.now()
                                      })
                                    })
                                    .catch(e => console.log('Error update master page: ', e.message))
                                  })
        
                                  // LOGIC RUN INVOKE CRAWL
                                  var protocolSite = ''
                                  var homepageSite = ''
                                  if(eleSite.data().stringSearch.length > 0) {
                                    body.stringSearch = eleSite.data().stringSearch
                                  }
                                  protocolSite = eleSite.data().prot ? eleSite.data().prot : getUrl.protocol
                                  body.url = protocolSite + "//" + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                                  homepageSite = eleSite.data().homepage ? eleSite.data().homepage : ''
                                  body.takeScreenshots = 'no'
                                  body.triggeredBy = eleSite.id
                                  body.source = docRefCrawl.id
                                  body.type = 'only-urls'
                                  body.useFastCrawler = 'no'
                                  body.typePage = 'homeXML'
                                  body.parseAsTextContent = 'yes'
        
                                  var urlOrigin = body.url
                                  
                                  // var config = {
                                  //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                  //   "method": "POST",
                                  //   "timeout": 0,
                                  //   "headers": {
                                  //     "Content-Type": "application/json",
                                  //     "Accept": "application/json"
                                  //   },
                                  //   "data": JSON.stringify(body),
                                  // };

                                  var config = {
                                    method: 'POST',
                                    url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                    maxBodyLength: Infinity,
                                    timeout: 0,
                                    headers: {
                                      'Content-Type': 'application/json',
                                      'Accept': 'application/json'
                                    },
                                    data: JSON.stringify(body)
                                  };
                
                                  this._vm.axios(config)
                                  .then((response) => {
                                    body.url = urlOrigin
                                    body.takeScreenshots = 'no'
                                    body.type = 'full'
                                    body.typePage = 'normal'
                                    // console.log(response.data)
                                    console.log('invoke home')
                                  })
                                  .catch(e => {
                                    console.log(e)
                                  })
                                  
                                  // run crawl three initials xml
                                  // body.typePage = 'normal'
                                  var atomicBody = body
                                  var countInvocked = 0
                                  // let arraySites = [atomicBody.url + "/site-map.xml", atomicBody.url + "/sitemap.xml", atomicBody.url + "/page-sitemap.xml"]
                                  let arraySites = [atomicBody.url + (atomicBody.url.endsWith('/') ? 'sitemap.xml' : '/sitemap.xml')]
                                  // let arraySites = []
                                  arraySites.forEach(res => {
                                    var objXMLCrawl = {
                                      forceNotFound: 'yes',
                                      id: body.id,
                                      ignoreIndexNoFollow: 'yes',
                                      pageSize: 999999999,
                                      screenshotComment: '',
                                      screenshotType: 'test',
                                      source: body.source,
                                      stringSearch: body.stringSearch,
                                      takeScreenshots: 'no',
                                      triggeredBy: body.triggeredBy,
                                      type: 'only-urls',
                                      url: res,
                                      useFastCrawler: 'no',
                                      typePage: 'normal',
                                      parseAsTextContent: 'no'
                                    }
                                    atomicBody.url = res
                                    atomicBody.type = 'only-urls'
                                    atomicBody.takeScreenshots = 'no'
                                    atomicBody.typePage = 'normal'
                                    
                                    // var config = {
                                    //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                    //   "method": "POST",
                                    //   "timeout": 0,
                                    //   "headers": {
                                    //     "Content-Type": "application/json",
                                    //     "Accept": "application/json"
                                    //   },
                                    //   "data": JSON.stringify(atomicBody),
                                    // };
                                    body.url = urlOrigin
                                    body.type = 'full'
        
                                    var config = {
                                      method: 'POST',
                                      url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                      maxBodyLength: Infinity,
                                      timeout: 0,
                                      headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json'
                                      },
                                      data: JSON.stringify(objXMLCrawl)
                                    };
                  
                                    this._vm.axios(config)
                                    .then((response) => {
                                      countInvocked += 1
                                      // body.url = urlOrigin
                                      // if(countInvocked === 3) {
                                        // console.log(response.data)
                                        console.log('invoke init xml')
                                        body.stringSearch = ["PHP ERROR",'Lorem','Ipsum']
                                        body.takeScreenshots = 'no'
                                        body.screenshotComment = ""
                                        body.url = urlOrigin
                                      // }
                                    })
                                    .catch(e => {
                                      console.log(e)
                                    })
                                  })
        
                                  db.collection('Sites').doc(eleSite.id).update({
                                    mostRecentCrawlStatus: 'running',
                                    mostRecentCrawl: docRefCrawl.id,
                                    mostRecentCrawlId: body.id,
                                    pages: [],
                                    // pageSizes: [],
                                    externalLinks: [],
                                    plus1k: false
                                  })
                                  .catch(e => console.log('Error update site: ', e))
        
                                  db.collection('Sites_cache').doc(eleSite.id).update({
                                    mostRecentCrawlStatus: 'running',
                                    mostRecentCrawl: docRefCrawl.id,
                                    mostRecentCrawlId: body.id,
                                    pages: [],
                                    inlinks: [],
                                    plus1k: false
                                  })
                                  .catch(e => console.log('Error update site: ', e))
        
                                  URLsfound.forEach(eleC => {
                                    var objCache = {}
                                    objCache.id = null
                                    objCache.title = null
                                    objCache.url = eleC.url
                                    objCache.inlinks = null
                                    objCache.inlinksNumber = null
                                    objCache.pro = false
                                    objCache.status = null
                                    objCache.redirectTo = null
                                    objCache.blockedByRobots = null
                                    objCache.totalErrors = 0
                                    objCache.errorByType = {}
                                    objCache.isSitemap = eleC.url.includes('.xml') ? true : false
                                    db.collection('Sites_cache').doc(eleSite.id).update({
                                      pages: firebase.firestore.FieldValue.arrayUnion(objCache)
                                    })
        
                                    var objPageCollection = {
                                      id: null,
                                      title: null,
                                      url: eleC.url,
                                      inlinks: [],
                                      inlinksNumber: 0,
                                      pro: false,
                                      // status: itemPage.status,
                                      status: null,
                                      redirectTo: null,
                                      blockedByRobots: null,
                                      totalErrors: 0,
                                      errorByType: {},
                                      active: false,
                                      crawleable: true,
                                      size: null,
                                      isSitemap: eleC.url.includes('.xml') ? true : false
                                    }
        
                                    db.collection('Sites').doc(eleSite.id).collection('pages').where('url', '==', eleC.url).limit(1).get()
                                    .then(docSite => {
                                      if(docSite.size) {
                                        var counterLoop = 0
                                        docSite.forEach(ele => {
                                          if(counterLoop === 0) {
                                            db.collection('Sites').doc(eleSite.id).collection('pages').doc(ele.id).update(objPageCollection)
                                          }
                                        })
                                      } else {
                                        db.collection('Sites').doc(eleSite.id).collection('pages').add(objPageCollection)
                                        .then((docRefSite) => {
                                            db.collection('Sites').doc(eleSite.id).collection('pages').doc(docRefSite.id).update({
                                              id: docRefSite.id
                                            })
                                        })
                                      }
                                    })
                                  })
                                })
                                .catch(e => console.log('Error add crawl site: ', e))
                              } else if(queryCrawl.size) {
                                console.log('Crawl running already!')
                              }
                            })
                            .catch(e => console.log(e.message))
                        // })
                      })
                      .catch(e => {
                        console.log('Error query sites host: ', e)
                      })
                    }

                    db.collection('Projects').doc(docRef.id).update({
                      numberSites: 1,
                    })

                    db.collection('Users').doc(payload.owned.uid).collection('projects').doc(docRef.id).update({
                      numberSites: 1,
                    })
      
                    setTimeout(() => {
                      db.collection('Users').doc(payload.owned.uid).get()
                      .then(docUser => {
                        var objnewSiteMail = {
                          nameUser: docUser.data().nombre ? docUser.data().nombre : docUser.data().email,
                          email: docUser.data().email,
                          existUser: true,
                          urlSite: URLdomain,
                          idsite: idSite,
                          idproject: docRef.id
                        }

                        // update seocloud-dcdfb
                        var confignewSiteMail = {
                          method: 'POST',
                          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/newSiteMail',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          data: JSON.stringify(objnewSiteMail),
                        }

                        this._vm.axios(confignewSiteMail)
                        .then(() => {
                          console.log('email sended')
                        })
                        .catch(error => {
                          console.log(error)
                        })
                      })
                      
                      this._vm.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Site created successfully`,
                          icon: 'CoffeeIcon',
                          variant: 'success'
                        },
                      })
                      router.push({ name: 'project-sites', params: {id: docRef.id, name: payload.projectName, own: 'me'} })
                    }, 1500)
                  } else {
                    db.collection('Sites').add({
                      'SiteID': '',
                      'name': payload.siteName,
                      'n': payload.siteName ? payload.siteName.toLowerCase() : '',
                      'WebsiteURL': URLdomain,
                      'homepage': urlBodyx,
                      thumbnail: existThumbnail,
                      'Blockedbyrobot': 'no',
                      // 'pagesFound': '',
                      'proPages': [],
                      'asignatedProPages': false,
                      'mostRecentCrawl': '',
                      'mostRecentCrawlStatus': '',
                      'totalErrors': 0,
                      'errorsCategory': {},
                      'claimed': false,
                      'owner': '',
                      'plan': '',
                      'paidRecurrence': '',
                      'totalPages': 0,
                      'createdBy': payload.owned.uid,
                      'created': Date.now(),
                      'registrationDay': moment().format('dddd'),

                      'owners': [],
                      'limitCrawl': 600,
                      'prot': URLprotocol,
                      'domainPrefix': urlPrefix,
                      'stringSearch': ['PHP ERROR','Lorem','Ipsum','Consectetur'],
                      'totalSuccessCrawl': 0,
                      'externalLinks': [],
                      'pageSizes': [],
                      'projects': [docRef.id],
                      'plus1k': false,
                      'teamMembers': [],
                      'errorsPages': [],
                      'suspiciousText': ['viagra'],
                      'n_guess_crawls': 0,
                      'n_log_crawls': 0
                      
                      // 'FirstFound': Date.now(),
                      // 'sizeValidate': true,
                      // 'Mostrecentfullcrawl': this.body.id,
                      // 'Mostrecentfullcrawldatestart': Date.now(),
                      // 'Mostrecentfullcrawldateend': null,
                      // 'Mostrecentfullcrawlsuccessid': null,
                      // 'Mostrecentfullcrawlsuccess': false,
                      // 'Mostrecentcrawltype': bodySite.type ? bodySite.type : 'full',
                      // 'Mostrecentsinglecrawl': null,
                      // 'MostrecentsinglecrawlURL': null,
                      // 'Mostrecentsinglecrawldate': null
                    })
                    .then(docRefSite => {
                      var dataSrc = JSON.stringify({
                        "website": urlBodyx
                      });
                
                      var configSrc = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: 'https://sslcheckerapi-h4klmyt43a-uc.a.run.app/screenshots/register',
                        headers: { 
                          'Content-Type': 'application/json'
                        },
                        data : dataSrc
                      };
                
                      this._vm.axios(configSrc)
                      .then((response) => {
                        console.log('register screenshots: ', response)
                      })
                      .catch((error) => {
                        console.error('Error register screenshot: ', error)
                      });

                      // update Sites id
                      db.collection('Sites').doc(docRefSite.id).update({
                        SiteID: docRefSite.id,
                        teamMembers: firebase.firestore.FieldValue.arrayUnion(payload.owned.uid)
                      })
                      .catch(e => console.log('Error update ref site: ', e))

                      db.collection('Users').doc(payload.owned.uid).get()
                      .then(docUser => {
                        var objnewSiteMail = {
                          nameUser: docUser.data().nombre ? docUser.data().nombre : docUser.data().email,
                          email: docUser.data().email,
                          existUser: true,
                          urlSite: URLdomain,
                          idsite: docRef.id,
                          idproject: docRefSite.id
                        }

                        // update seocloud-dcdfb
                        var confignewSiteMail = {
                          method: 'POST',
                          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/newSiteMail',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          data: JSON.stringify(objnewSiteMail),
                        }

                        this._vm.axios(confignewSiteMail)
                        .then(() => {
                          console.log('email sended')
                        })
                        .catch(error => {
                          console.log(error)
                        })
                      })

                      if(docRefSite.id) {
                        var body = {
                          url: '',
                          type: 'full',
                          forceNotFound: 'yes',
                          pageSize: 999999999,
                          id: 'seo-shops-full-' + Date.now(),
                          takeScreenshots: 'no',
                          source: 'seo-cloud-full-' + Date.now(),
                          triggeredBy: 'test triggered',
                          screenshotType: 'test',
                          screenshotComment: '',
                          stringSearch: ["PHP ERROR",'Lorem','Ipsum'],
                          ignoreIndexNoFollow: "yes"
                        }
                        var getUrl = new URL(site);
                        var hostUrl = getUrl.host;
                        var httpsString = getUrl.protocol + '//';
                        var urlConcat = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                        var urlBody = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                        var ultimoCaracterUrl = urlConcat.charAt(urlConcat.length - 1);
                        if(ultimoCaracterUrl != "/") {
                          urlConcat += "/";
                        }
          
                        body.id = 'seo-shops-full-' + Date.now()
                        body.source = 'seo-shops-full-' + Date.now()
                        body.url = getUrl.protocol + "//" + hostUrl
          
                        if(getUrl.pathname === "/") {
                          body.takeScreenshots = 'no'
                          body.screenshotComment = ""
                        }
          
                        db.collection('Sites').doc(docRefSite.id).get()
                        .then(eleSite => {
                          // exist site
                          // db.collection('Crawls').where('status', '==', 'running').get()
                          // .then(queryCrawlRunning => {
                            // if(querySites.) {
                            var countSites = 0
                            var pageSizes = eleSite.data().pageSizes && eleSite.data().pageSizes.length ? eleSite.data().pageSizes : []
                            var pagesSite = eleSite.data().pages && eleSite.data().pages.length ? eleSite.data().pages : []
                            var filterInternals = pagesSite.filter(ele => ele.isSitemap === true)
                            var filterNoInternals = pagesSite.filter(ele => ele.isSitemap === false)
                            var filterProPages = filterNoInternals.filter(ele => ele.pro === true)
                            var filterNoProPages = filterNoInternals.filter(ele => ele.pro === false)
                            var filterInlinks = filterNoProPages
          
                            var condicionParaOrdenar = (pageA, pageB) => {
                              return pageB.inlinks.length - pageA.inlinks.length;
                            }
                            filterInlinks.sort(condicionParaOrdenar)
          
                            var arrayFoundPages = []
                            var arrayProPages = []
                            filterProPages.forEach(ele => {
                              var getUrl = new URL(ele.url)
                              if(getUrl.pathname !== '/') {
                                arrayProPages.push(ele.url)
                              }
          
                              arrayFoundPages.unshift(ele.url)
                            })
          
                            // filterInternals.forEach(ele => {
                            //   var filterPage = arrayFoundPages.filter(item => item === ele.url)
                            //   if(!filterPage.length) {
                            //     arrayFoundPages.push(ele.url)
                            //   }
                            // })
                            
                              db.collection('Crawls').where('WebsiteURL', '==', hostUrl).where('status', '==', 'running').get()
                              .then(queryCrawl => {
                                // only 1 loop
                                if(countSites === 0 && !queryCrawl.size) {
                                  countSites += 1
                                  var stringsArray = eleSite.data().stringSearch ? eleSite.data().stringSearch : []
                                  var suspiciousTextArray = eleSite.data().suspiciousText ? eleSite.data().suspiciousText : []
                                  var arrayStringsSuspicious = []
                                  stringsArray.forEach(ele => {
                                    arrayStringsSuspicious.push(ele)
                                  })

                                  suspiciousTextArray.forEach(ele => {
                                    arrayStringsSuspicious.push(ele)
                                  })

                                  var getConfigCrawl = localStorage.getItem('configCrawl')
                                  var limitCrawl = 100
                                  var configCrawlType = 'JS FREE'
                                  var configCrawlPages = 'FREE'
                                  var setCrawl = false
                                  if(getConfigCrawl) {
                                    setCrawl = true
                                    var parseConfig = JSON.parse(getConfigCrawl)
                                    var logicTime = moment(parseConfig.date)
                                    var actualTime = moment(new Date())
                                    var diffSeconds = actualTime.diff(logicTime, 'seconds');
                                    if(diffSeconds < 600) {
                                      limitCrawl = parseConfig.pages
                                      configCrawlType = parseConfig.crawl
                                      configCrawlPages = parseConfig.pages === 25 ? 'GUESS' : parseConfig.pages === 100 ? 'FREE' : parseConfig.pages === 600 ? 'LICENSE' : 'FREE'
                                    }
                                    var getConfigCrawl = localStorage.removeItem('configCrawl')
                                  }
                                  
                                  var limitCrawlSetSite = eleSite.data().limitCrawl ? eleSite.data().limitCrawl : 0
                                  if(limitCrawlSetSite) {
                                    if(configCrawlPages === 'GUESS' && limitCrawlSetSite < 25) {
                                      limitCrawl = limitCrawlSetSite
                                    } else if(configCrawlPages === 'FREE' && limitCrawlSetSite < 100) {
                                      limitCrawl = limitCrawlSetSite
                                    } else if(configCrawlPages === 'LICENSE') {
                                      limitCrawl = limitCrawlSetSite
                                    }
                                  }
          
                                  // add Crawl
                                  db.collection('Crawls').add({
                                    'IDcrawl': body.id,
                                    'dateStart': Date.now(),
                                    'dateStartFormat': new Date(),
                                    'dateEnd': null,
                                    'crawlDuration': null,
                                    'status': 'running',
                                    'crawltype': 'full',
                                    'ignoreindexnofollow': 'yes',
                                    'Requests': 1,
                                    'SiteID': eleSite.id,
                                    'WebsiteURL': hostUrl,
                                    'blockedByRobots': false,
                                    'logicRequests': 1,
                                    'proPages': [urlBody],
                                    'totalErrors': 0,
                                    'limitCrawl': limitCrawl,
                                    'stringSearch': arrayStringsSuspicious,
                                    'founds': arrayFoundPages,
                                    'requests': [],
                                    'pages': [],
                                    'inlinks': [],
                                    'external': [],
                                    'pageSizes': pageSizes,
                                    'updated': Date.now(),
                                    'plus1k': false,
                                    'errorsPages': [],
                                    'configCrawlType': 'JS FREE',
                                    'configCrawlPages': 'FREE',
                                    'errorsTypePage': [],
                                    'timeoutPages': 0,
                                    'errorsCategory': {}
                                  })
                                  .then((docRefCrawl) => {
                                    var URLsfound = [
                                      {
                                        'id': null,
                                        'title': null,
                                        'runPosition': 1,
                                        'lastRequest': null,
                                        'pro': true,
                                        'inlinks': [],
                                        'status': null,
                                        'redirectTo': null,
                                        'blockedByRobots': false,
                                        // 'totalErrors': 0,
          
                                        'crawleable': true,
                                        'crawled': false,
                                        'crawlerInvoke': true,
                                        'url': urlBody
                                        
                                      },
                                      // {
                                      //   'id': null,
                                      //   'title': null,
                                      //   'runPosition': 2,
                                      //   'lastRequest': null,
                                      //   'pro': false,
                                      //   'inlinks': [],
                                      //   'status': null,
                                      //   'redirectTo': null,
                                      //   'blockedByRobots': false,
          
                                      //   'crawleable': true,
                                      //   'crawled': false,
                                      //   'crawlerInvoke': true,
                                      //   'url': urlConcat + "site-map.xml"
                                      // },
                                      {
                                        'id': null,
                                        'title': null,
                                        'runPosition': 2,
                                        'lastRequest': null,
                                        'pro': false,
                                        'inlinks': [],
                                        'status': null,
                                        'redirectTo': null,
                                        'blockedByRobots': false,
          
                                        'crawleable': true,
                                        'crawled': false,
                                        'crawlerInvoke': true,
                                        'url': urlConcat + "sitemap.xml"
                                      },
                                      // {
                                      //   'id': null,
                                      //   'title': null,
                                      //   'runPosition': 4,
                                      //   'lastRequest': null,
                                      //   'pro': false,
                                      //   'inlinks': [],
                                      //   'status': null,
                                      //   'redirectTo': null,
                                      //   'blockedByRobots': false,
          
                                      //   'crawleable': true,
                                      //   'crawled': false,
                                      //   'crawlerInvoke': true,
                                      //   'url': urlConcat + "page-sitemap.xml"
                                      // }
                                    ]

                                    db.collection('Sites').doc(eleSite.id).update({
                                      mostRecentCrawl: docRefCrawl.id,
                                      n_log_crawls: eleSite.data().n_log_crawls ? firebase.firestore.FieldValue.increment(1) : 1,
                                      totalErrors: 0
                                    })
          
                                    // add pages crawl
                                    URLsfound.forEach(eleC => {
                                      db.collection('Crawls').doc(docRefCrawl.id).update({
                                        requests: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                      })
          
                                      db.collection('Crawls').doc(docRefCrawl.id).update({
                                        founds: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                      })
          
                                      db.collection('Crawls').doc(docRefCrawl.id).update({
                                        pages: firebase.firestore.FieldValue.arrayUnion({
                                          id: '',
                                          title: '',
                                          runPosition: null,
                                          lastRequest: null,
                                          pro: false,
                                          inlinks: [],
                                          status: null,
                                          redirectTo: '',
                                          blockedByRobots: false,
                                          crawled: true,
                                          crawleable: true,
                                          url: eleC.url,
                                          isSitemap: false,
                                          hasRun: false,
                                          active: false,
                                          size: 999999999,
                                          tries: 1,
                                          tryTimestamp: Date.now()
                                        })
                                      })
                                      .catch(e => console.log('Error update master page: ', e.message))
                                    })
          
                                    // LOGIC RUN INVOKE CRAWL
                                    var protocolSite = ''
                                    var homepageSite = ''
                                    if(eleSite.data().stringSearch.length > 0) {
                                      body.stringSearch = eleSite.data().stringSearch
                                    }
                                    protocolSite = eleSite.data().prot ? eleSite.data().prot : getUrl.protocol
                                    body.url = protocolSite + "//" + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                                    homepageSite = eleSite.data().homepage ? eleSite.data().homepage : ''
                                    body.takeScreenshots = 'no'
                                    body.triggeredBy = eleSite.id
                                    body.source = docRefCrawl.id
                                    body.type = 'only-urls'
                                    body.useFastCrawler = 'no'
                                    body.typePage = 'homeXML'
                                    body.parseAsTextContent = 'yes'
          
                                    var urlOrigin = body.url
                                    
                                    // var config = {
                                    //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                    //   "method": "POST",
                                    //   "timeout": 0,
                                    //   "headers": {
                                    //     "Content-Type": "application/json",
                                    //     "Accept": "application/json"
                                    //   },
                                    //   "data": JSON.stringify(body),
                                    // };
          
                                    var config = {
                                      method: 'POST',
                                      url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                      maxBodyLength: Infinity,
                                      timeout: 0,
                                      headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json'
                                      },
                                      data: JSON.stringify(body)
                                    };
                  
                                    this._vm.axios(config)
                                    .then((response) => {
                                      body.url = urlOrigin
                                      body.takeScreenshots = 'no'
                                      body.type = 'full'
                                      body.typePage = 'normal'
                                      // console.log(response.data)
                                      console.log('invoke home')
                                    })
                                    .catch(e => {
                                      console.log(e)
                                    })
                                    
                                    // run crawl three initials xml
                                    // body.typePage = 'normal'
                                    var atomicBody = body
                                    var countInvocked = 0
                                    // let arraySites = [atomicBody.url + "/site-map.xml", atomicBody.url + "/sitemap.xml", atomicBody.url + "/page-sitemap.xml"]
                                    let arraySites = [atomicBody.url + (atomicBody.url.endsWith('/') ? 'sitemap.xml' : '/sitemap.xml')]
                                    // let arraySites = []
                                    arraySites.forEach(res => {
                                      var objXMLCrawl = {
                                        forceNotFound: 'yes',
                                        id: body.id,
                                        ignoreIndexNoFollow: 'yes',
                                        pageSize: 999999999,
                                        screenshotComment: '',
                                        screenshotType: 'test',
                                        source: body.source,
                                        stringSearch: body.stringSearch,
                                        takeScreenshots: 'no',
                                        triggeredBy: body.triggeredBy,
                                        type: 'only-urls',
                                        url: res,
                                        useFastCrawler: 'no',
                                        typePage: 'normal',
                                        parseAsTextContent: 'no'
                                      }
                                      atomicBody.url = res
                                      atomicBody.type = 'only-urls'
                                      atomicBody.takeScreenshots = 'no'
                                      atomicBody.typePage = 'normal'
                                      
                                      // var config = {
                                      //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                      //   "method": "POST",
                                      //   "timeout": 0,
                                      //   "headers": {
                                      //     "Content-Type": "application/json",
                                      //     "Accept": "application/json"
                                      //   },
                                      //   "data": JSON.stringify(atomicBody),
                                      // };
                                      body.url = urlOrigin
                                      body.type = 'full'
          
                                      var config = {
                                        method: 'POST',
                                        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                        maxBodyLength: Infinity,
                                        timeout: 0,
                                        headers: {
                                          'Content-Type': 'application/json',
                                          'Accept': 'application/json'
                                        },
                                        data: JSON.stringify(objXMLCrawl)
                                      };
                    
                                      this._vm.axios(config)
                                      .then((response) => {
                                        countInvocked += 1
                                        // body.url = urlOrigin
                                        // if(countInvocked === 3) {
                                          // console.log(response.data)
                                          console.log('invoke init xml')
                                          body.stringSearch = ["PHP ERROR",'Lorem','Ipsum']
                                          body.takeScreenshots = 'no'
                                          body.screenshotComment = ""
                                          body.url = urlOrigin
                                        // }
                                      })
                                      .catch(e => {
                                        console.log(e)
                                      })
                                    })
          
                                    db.collection('Sites').doc(eleSite.id).update({
                                      mostRecentCrawlStatus: 'running',
                                      mostRecentCrawl: docRefCrawl.id,
                                      mostRecentCrawlId: body.id,
                                      pages: [],
                                      // pageSizes: [],
                                      externalLinks: [],
                                      plus1k: false
                                    })
                                    .catch(e => console.log('Error update site: ', e))
          
                                    db.collection('Sites_cache').doc(eleSite.id).update({
                                      mostRecentCrawlStatus: 'running',
                                      mostRecentCrawl: docRefCrawl.id,
                                      mostRecentCrawlId: body.id,
                                      pages: [],
                                      inlinks: [],
                                      plus1k: false
                                    })
                                    .catch(e => console.log('Error update site: ', e))
          
                                    URLsfound.forEach(eleC => {
                                      var objCache = {}
                                      objCache.id = null
                                      objCache.title = null
                                      objCache.url = eleC.url
                                      objCache.inlinks = null
                                      objCache.inlinksNumber = null
                                      objCache.pro = false
                                      objCache.status = null
                                      objCache.redirectTo = null
                                      objCache.blockedByRobots = null
                                      objCache.totalErrors = 0
                                      objCache.errorByType = {}
                                      objCache.isSitemap = eleC.url.includes('.xml') ? true : false
                                      db.collection('Sites_cache').doc(eleSite.id).update({
                                        pages: firebase.firestore.FieldValue.arrayUnion(objCache)
                                      })
          
                                      var objPageCollection = {
                                        id: null,
                                        title: null,
                                        url: eleC.url,
                                        inlinks: [],
                                        inlinksNumber: 0,
                                        pro: false,
                                        // status: itemPage.status,
                                        status: null,
                                        redirectTo: null,
                                        blockedByRobots: null,
                                        totalErrors: 0,
                                        errorByType: {},
                                        active: false,
                                        crawleable: true,
                                        size: null,
                                        isSitemap: eleC.url.includes('.xml') ? true : false
                                      }
          
                                      db.collection('Sites').doc(eleSite.id).collection('pages').where('url', '==', eleC.url).limit(1).get()
                                      .then(docSite => {
                                        if(docSite.size) {
                                          var counterLoop = 0
                                          docSite.forEach(ele => {
                                            if(counterLoop === 0) {
                                              db.collection('Sites').doc(eleSite.id).collection('pages').doc(ele.id).update(objPageCollection)
                                            }
                                          })
                                        } else {
                                          db.collection('Sites').doc(eleSite.id).collection('pages').add(objPageCollection)
                                          .then((docRefSite) => {
                                              db.collection('Sites').doc(eleSite.id).collection('pages').doc(docRefSite.id).update({
                                                id: docRefSite.id
                                              })
                                          })
                                        }
                                      })
                                    })
                                  })
                                  .catch(e => console.log('Error add crawl site: ', e))
                                } else if(queryCrawl.size) {
                                  console.log('Crawl running already!')
                                }
                              })
                              .catch(e => console.log(e.message))
                          // })
                        })
                        .catch(e => {
                          console.log('Error query sites host: ', e)
                        })
                      }

                      // var objPage = {}
                      // objPage.id = null
                      // objPage.title = null
                      // objPage.url = urlBody
                      // objPage.inlinks = []
                      // objPage.inlinksNumber = 0
                      // objPage.size = null
                      // objPage.pro = false
                      // objPage.status = null
                      // objPage.redirectTo = null
                      // objPage.blockedByRobots = null
                      // objPage.totalErrors = 0
                      // objPage.errorByType = {}
                      // objPage.active = null
                      // objPage.crawleable = true
                      // objPage.isSitemap = false
                      // objPage.lastRequest = null
                      // db.collection('Sites').doc(docRefSite.id).update({
                      //   pages: firebase.firestore.FieldValue.arrayUnion(objPage),
                      //   proPages: firebase.firestore.FieldValue.arrayUnion(urlBody)
                      // })

                      // var objCache = {}
                      // objCache.id = null
                      // objCache.title = null
                      // objCache.url = urlBody
                      // objCache.inlinks = null
                      // objCache.inlinksNumber = null
                      // objCache.pro = false
                      // objCache.status = null
                      // objCache.redirectTo = null
                      // objCache.blockedByRobots = null
                      // objCache.totalErrors = 0
                      // objCache.errorByType = {}
                      // objCache.isSitemap = false
                      // db.collection('Sites_cache').doc(docRefSite.id).update({
                      //   pages: firebase.firestore.FieldValue.arrayUnion(objCache),
                      //   proPages: firebase.firestore.FieldValue.arrayUnion(urlBody)
                      // })

                      db.collection('Sites_cache').doc(docRefSite.id).set({
                        'SiteID': docRefSite.id,
                        'name': payload.siteName,
                        'n': payload.siteName ? payload.siteName.toLowerCase() : '',
                        'WebsiteURL': URLdomain,
                        'homepage': urlBodyx,
                        thumbnail: existThumbnail,
                        'Blockedbyrobot': 'no',
                        // 'pagesFound': '',
                        'proPages': [],
                        inlinks: [],
                        'asignatedProPages': false,
                        'mostRecentCrawl': '',
                        'mostRecentCrawlStatus': '',
                        'totalErrors': 0,
                        'errorsCategory': {},
                        'claimed': false,
                        'owner': '',
                        'plan': '',
                        'paidRecurrence': '',
                        'totalPages': 0,
                        'createdBy': payload.owned.uid,
                        'projects': [docRef.id],
                        'plus1k': false
                      })
                      .catch(e => console.log('Error add site cache: ', e))
                      
                      // add site to project
                      db.collection('Projects').doc(docRef.id).collection('sites').doc(docRefSite.id).set({
                        'SiteID': docRefSite.id,
                        'WebsiteURL': URLdomain,
                        'homepage': urlBodyx,
                        'name': payload.siteName,
                        'n': payload.siteName ? payload.siteName.toLowerCase() : '',
                        thumbnail: existThumbnail,
                        'totalErrors': 0,
                        'plan': '',
                        'paidRecurrence': '',
                        'claimed': false,
                        'owner': '',
                        'status': 'active',
                        // projectId: docRef.id,
                      })
                      .catch(error => {
                        console.error(error)
                      })

                      db.collection('Projects').doc(docRef.id).update({
                        numberSites: 1,
                      })

                      db.collection('Users').doc(payload.owned.uid).collection('projects').doc(docRef.id).update({
                        numberSites: 1,
                      })

                      // create teamMembers
                      if(payload.projectInvitations.length >= 1) {
                        // const members = []
                        // const membersEmail = []
                        let member = ''
                        payload.projectInvitations.forEach(item => {
                          if(item.email.includes('@') && item.email.includes('.')) {
                            const email = item.email.trim()
                            db.collection('Users').where('email', '==', email).get()
                            .then(queryUser => {
                              // verify if user already exist, to add uid
                              var userID = ''
                              if(queryUser.size >= 1) {
                                queryUser.forEach(userItem => {
                                  userID = userItem.id
                                  // members.push({
                                  //   uid: userItem.id,
                                  //   email,
                                  //   perm: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                                  //   imgU: userItem.data().foto ? userItem.data().foto : '',
                                  //   status: 'invitated',
                                  //   statusInv: 'sended',
                                  //   // status: 'active',
                                  //   // statusInv: 'accepted'
                                  // })

                                  member = {
                                    id: userItem.id,
                                    email,
                                    name: userItem.data().nombre ? userItem.data().nombre : '',
                                    role: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                                    status: 'invitated',
                                    statusInv: 'sended',
                                    avatarURL: userItem.data().foto ? userItem.data().foto : '',
                                    // status: 'active',
                                    // statusInv: 'accepted'
                                  }

                                  // update teams of user belongs
                                  if(userItem.data().teams) {
                                    db.collection('Users').doc(userItem.id).update({
                                      projects: firebase.firestore.FieldValue.arrayUnion(docRef.id),
                                    })
                                    .catch(error => {
                                      console.error(error)
                                    })

                                    db.collection('Users').doc(userItem.id).collection('projects').doc(docRef.id).set({
                                      id: docRef.id,
                                      name: payload.projectName ? payload.projectName : '',
                                      color: payload.projectColor,
                                      isMySites: false,
                                      numberSites: 1,
                                      // sites: site ? site : '',
                                      numberTeamMembers: payload.projectInvitations.length,
                                      owner: payload.owned ? payload.owned : '',
                                      teamMembers: []
                                    })
                                    .catch(error => {
                                      console.error(error)
                                    })

                                    // db.collection('projects').doc(docRef.id).update({
                                    //   teamMembers: firebase.firestore.FieldValue.arrayUnion(userItem.id),
                                    // })
                                    // .catch(error => {
                                    //   console.error(error)
                                    // })

                                    // db.collection('project-details').doc(docRef.id).update({
                                    //   teamMembers: firebase.firestore.FieldValue.arrayUnion(userItem.id),
                                    // })
                                    // .catch(error => {
                                    //   console.error(error)
                                    // })
                                  } else {
                                    db.collection('Users').doc(userItem.id).update({
                                      projects: [docRef.id],
                                    })
                                    .catch(error => {
                                      console.error(error)
                                    })

                                    db.collection('Users').doc(userItem.id).collection('projects').doc(docRef.id).set({
                                      id: docRef.id,
                                      name: payload.projectName ? payload.projectName : '',
                                      color: payload.projectColor,
                                      isMySites: false,
                                      numberSites: 1,
                                      // sites: site ? site : '',
                                      numberTeamMembers: payload.projectInvitations.length,
                                      owner: payload.owned ? payload.owned : '',
                                      teamMembers: []
                                    })
                                    .catch(error => {
                                      console.error(error)
                                    })

                                    // db.collection('projects').doc(docRef.id).update({
                                    //   teamMembers: [userItem.id],
                                    // })
                                    // .catch(error => {
                                    //   console.error(error)
                                    // })

                                    // db.collection('project-details').doc(docRef.id).update({
                                    //   teamMembers: [userItem.id],
                                    // })
                                    // .catch(error => {
                                    //   console.error(error)
                                    // })
                                  }
                                })
                              } else {
                                userID = ''
                                // members.push({
                                //   uid: '',
                                //   email,
                                //   perm: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                                //   status: 'invitated',
                                //   statusInv: 'sended',
                                //   // status: 'active',
                                //   // statusInv: 'accepted'
                                // })

                                member = {
                                  id: '',
                                  email,
                                  name: '',
                                  role: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                                  status: 'invitated',
                                  statusInv: 'sended',
                                  avatarURL: '',
                                  // status: 'active',
                                  // statusInv: 'accepted'
                                }
                              }

                              // send invitation mails
                              const obj = {
                                email,
                                name: payload.owned.nombre ? payload.owned.nombre : payload.owned.email ? payload.owned.email : '',
                                project: payload.projectName,
                                existUser: queryUser.size >= 1 ? true : false
                              }

                              // update seocloud-dcdfb
                              const config = {
                                method: 'POST',
                                url: 'https://us-central1-seocloud-7e24d.cloudfunctions.net/invitationMail',
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                                data: JSON.stringify(obj),
                              }

                              this._vm.axios(config)
                              .then(() => {
                                console.log('email sended')
                              })
                              .catch(error => {
                                console.log(error)
                              })

                              // members projects
                              db.collection('Projects').doc(docRef.id).collection('members').add(member)
                              .catch(error => {
                                console.error(error)
                              })

                              // update array members project
                              db.collection('Projects').doc(docRef.id).get()
                              .then(docProject => {
                                var membersProject = docProject.data().teamMembers ? docProject.data().teamMembers : []
                                var filterMembers = membersProject.filter(eleMember => eleMember.email === member.email)
                                if(!filterMembers.length) {
                                  db.collection('Projects').doc(docRef.id).update({
                                    teamMembers: membersProject.concat(member)
                                  })

                                  if(userID) {
                                    db.collection('Users').doc(userID).collection('projects').doc(docRef.id).update({
                                      teamMembers: membersProject.concat(member)
                                    })
                                  }

                                  db.collection('Users').doc(payload.owned.uid).collection('projects').doc(docRef.id).update({
                                    teamMembers: membersProject.concat(member)
                                  })
                                }
                              })
                              
                              
                              // members project details
                              // db.collection('project-details').doc(docRef.id).collection('members').add(member)
                              // .catch(error => {
                              //   console.error(error)
                              // })
                            })
                            .catch(error => {
                              console.log(error)
                            })
                            // membersEmail.push(email)
                          }
                        })

                        // db.collection('projects').doc(docRef.id).collection('members').doc('resume')
                        // .set({
                        //   members,
                        //   membersEmail,
                        //   owned: payload.owned,
                        //   date: Date.now(),
                        //   uid: 'resume',
                        // })
                        // .catch(error => {
                        //   console.error(error)
                        // })
                      }
                    })
                  }
                } else {
                  this._vm.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'You have already created this site',
                      icon: 'CoffeeIcon',
                      variant: 'warning',
                      text: 'The site was not created',
                    },
                  })

                  // create teamMembers
                  if(payload.projectInvitations.length >= 1) {
                    const members = []
                    // const membersEmail = []
                    let member = ''
                    payload.projectInvitations.forEach(item => {
                      if(item.email.includes('@') && item.email.includes('.')) {
                        const email = item.email.trim()
                        db.collection('Users').where('email', '==', email).get()
                        .then(queryUser => {
                          // verify if user already exist, to add uid
                          var userID = ''
                          if(queryUser.size >= 1) {
                            queryUser.forEach(userItem => {
                              userID = userItem.id
                              // members.push({
                              //   uid: userItem.id,
                              //   email,
                              //   perm: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                              //   imgU: userItem.data().foto ? userItem.data().foto : '',
                              //   status: 'invitated',
                              //   statusInv: 'sended',
                              //   // status: 'active',
                              //   // statusInv: 'accepted'
                              // })

                              member = {
                                id: userItem.id,
                                email,
                                name: userItem.data().nombre ? userItem.data().nombre : '',
                                role: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                                status: 'invitated',
                                statusInv: 'sended',
                                avatarURL: userItem.data().foto ? userItem.data().foto : '',
                                // status: 'active',
                                // statusInv: 'accepted'
                              }

                              // update teams of user belongs
                              if(userItem.data().teams) {
                                db.collection('Users').doc(userItem.id).update({
                                  projects: firebase.firestore.FieldValue.arrayUnion(docRef.id),
                                })
                                .catch(error => {
                                  console.error(error)
                                })

                                db.collection('Users').doc(userItem.id).collection('projects').doc(docRef.id).set({
                                  id: docRef.id,
                                  name: payload.projectName ? payload.projectName : '',
                                  color: payload.projectColor,
                                  isMySites: false,
                                  numberSites: 0,
                                  // sites: site ? site : '',
                                  numberTeamMembers: payload.projectInvitations.length,
                                  owner: payload.owned ? payload.owned : '',
                                  teamMembers: []
                                })
                                .catch(error => {
                                  console.error(error)
                                })

                                // db.collection('projects').doc(docRef.id).update({
                                //   teamMembers: firebase.firestore.FieldValue.arrayUnion(userItem.id),
                                // })
                                // .catch(error => {
                                //   console.error(error)
                                // })

                                // db.collection('project-details').doc(docRef.id).update({
                                //   teamMembers: firebase.firestore.FieldValue.arrayUnion(userItem.id),
                                // })
                                // .catch(error => {
                                //   console.error(error)
                                // })
                              } else {
                                db.collection('Users').doc(userItem.id).update({
                                  projects: [docRef.id],
                                })
                                .catch(error => {
                                  console.error(error)
                                })

                                db.collection('Users').doc(userItem.id).collection('projects').doc(docRef.id).set({
                                  id: docRef.id,
                                  name: payload.projectName ? payload.projectName : '',
                                  color: payload.projectColor,
                                  isMySites: false,
                                  numberSites: 0,
                                  // sites: site ? site : '',
                                  numberTeamMembers: payload.projectInvitations.length,
                                  owner: payload.owned ? payload.owned : '',
                                  teamMembers: []
                                })
                                .catch(error => {
                                  console.error(error)
                                })

                                // db.collection('projects').doc(docRef.id).update({
                                //   teamMembers: [userItem.id],
                                // })
                                // .catch(error => {
                                //   console.error(error)
                                // })

                                // db.collection('project-details').doc(docRef.id).update({
                                //   teamMembers: [userItem.id],
                                // })
                                // .catch(error => {
                                //   console.error(error)
                                // })
                              }
                            })
                          } else {
                            userID = ''
                            // members.push({
                            //   uid: '',
                            //   email,
                            //   perm: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                            //   status: 'invitated',
                            //   statusInv: 'sended',
                            //   // status: 'active',
                            //   // statusInv: 'accepted'
                            // })

                            member = {
                              id: '',
                              email,
                              name: '',
                              role: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                              status: 'invitated',
                              statusInv: 'sended',
                              avatarURL: '',
                              // status: 'active',
                              // statusInv: 'accepted'
                            }
                          }

                          // send invitation mails
                          const obj = {
                            email,
                            name: payload.owned.nombre ? payload.owned.nombre : payload.owned.email ? payload.owned.email : '',
                            project: payload.projectName,
                            existUser: queryUser.size >= 1 ? true : false
                          }

                          // update seocloud-dcdfb
                          const config = {
                            method: 'POST',
                            url: 'https://us-central1-seocloud-7e24d.cloudfunctions.net/invitationMail',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            data: JSON.stringify(obj),
                          }

                          this._vm.axios(config)
                          .then(() => {
                            console.log('email sended')
                          })
                          .catch(error => {
                            console.log(error)
                          })

                          // members projects
                          db.collection('Projects').doc(docRef.id).collection('members').add(member)
                          .catch(error => {
                            console.error(error)
                          })

                          // update array members project
                          db.collection('Projects').doc(docRef.id).get()
                          .then(docProject => {
                            var membersProject = docProject.data().teamMembers ? docProject.data().teamMembers : []
                            var filterMembers = membersProject.filter(eleMember => eleMember.email === member.email)
                            if(!filterMembers.length) {
                              db.collection('Projects').doc(docRef.id).update({
                                teamMembers: membersProject.concat(member)
                              })

                              if(userID) {
                                db.collection('Users').doc(userID).collection('projects').doc(docRef.id).update({
                                  teamMembers: membersProject.concat(member)
                                })
                              }

                              db.collection('Users').doc(payload.owned.uid).collection('projects').doc(docRef.id).update({
                                teamMembers: membersProject.concat(member)
                              })
                            }
                          })
                          
                          
                          // members project details
                          // db.collection('project-details').doc(docRef.id).collection('members').add(member)
                          // .catch(error => {
                          //   console.error(error)
                          // })
                        })
                        .catch(error => {
                          console.log(error)
                        })
                        // membersEmail.push(email)
                      }
                    })

                    // db.collection('projects').doc(docRef.id).collection('members').doc('resume')
                    // .set({
                    //   members,
                    //   membersEmail,
                    //   owned: payload.owned,
                    //   date: Date.now(),
                    //   uid: 'resume',
                    // })
                    // .catch(error => {
                    //   console.error(error)
                    // })
                  }
                }
              })
            })
          } else {
            var urlBody = httpsString + URLdomain + URLpathname
            var urlBodyx = httpsString + URLdomain + URLpathname
            var URLdomainWithPath = URLdomain + URLpathname
            var URLwww = ''
            var URLwithoutWWW = ''
            var urlPrefix = 'www'
            if(URLdomain.includes('www.')) {
              urlPrefix = 'www'
              URLwww = URLdomain
              URLwithoutWWW = URLdomain.slice(4)
            } else {
              urlPrefix = 'non-www'
              URLwww = 'www.'+URLdomain
              URLwithoutWWW = URLdomain
            }

            // QUERY EXIST SITE
            db.collection('Sites').where('WebsiteURL', 'in', [URLdomainWithPath]).get()
            .then((querySiteThumb) => {
              var existThumbnail = ''
              var idSite =  ''
              if(querySiteThumb.size) {
                querySiteThumb.forEach(itemSite => {
                  idSite = itemSite.id
                  if(itemSite.data().thumbnail) {
                    existThumbnail = itemSite.data().thumbnail
                  }
                })
              }

              db.collection('Sites').where('WebsiteURL', 'in', [URLdomainWithPath]).where('createdBy', '==', payload.owned.uid).get()
              .then((querySite) => {
                if(!querySite.size) {
                  if(querySiteThumb.size) {
                    db.collection('Sites').doc(idSite).update({
                      teamMembers: firebase.firestore.FieldValue.arrayUnion(payload.owned.uid),
                      projects: firebase.firestore.FieldValue.arrayUnion(docRef.id)
                    })
                    .catch(e => console.log('Error update ref site: ', e))

                    var dataSrc = JSON.stringify({
                      "website": urlBodyx
                    });
              
                    var configSrc = {
                      method: 'post',
                      maxBodyLength: Infinity,
                      url: 'https://sslcheckerapi-h4klmyt43a-uc.a.run.app/screenshots/register',
                      headers: { 
                        'Content-Type': 'application/json'
                      },
                      data : dataSrc
                    };
              
                    this._vm.axios(configSrc)
                    .then((response) => {
                      console.log('register screenshots: ', response)
                    })
                    .catch((error) => {
                      console.error('Error register screenshot: ', error)
                    });
                    
                    // add site to project
                    db.collection('Projects').doc(docRef.id).collection('sites').doc(idSite).set({
                      'SiteID': idSite,
                      'WebsiteURL': URLdomainWithPath,
                      'homepage': urlBodyx,
                      'name': payload.siteName,
                      'n': payload.siteName ? payload.siteName.toLowerCase() : '',
                      thumbnail: existThumbnail,
                      'totalErrors': 0,
                      'plan': '',
                      'paidRecurrence': '',
                      'claimed': false,
                      'owner': '',
                      'status': 'active',
                      // projectId: docRef.id,
                    })
                    .catch(error => {
                      console.error(error)
                    })

                    if(idSite) {
                      var body = {
                        url: '',
                        type: 'full',
                        forceNotFound: 'yes',
                        pageSize: 999999999,
                        id: 'seo-shops-full-' + Date.now(),
                        takeScreenshots: 'no',
                        source: 'seo-cloud-full-' + Date.now(),
                        triggeredBy: 'test triggered',
                        screenshotType: 'test',
                        screenshotComment: '',
                        stringSearch: ["PHP ERROR",'Lorem','Ipsum'],
                        ignoreIndexNoFollow: "yes"
                      }
                      var getUrl = new URL(site);
                      var hostUrl = getUrl.host;
                      var httpsString = getUrl.protocol + '//';
                      var urlConcat = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                      var urlBody = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                      var ultimoCaracterUrl = urlConcat.charAt(urlConcat.length - 1);
                      if(ultimoCaracterUrl != "/") {
                        urlConcat += "/";
                      }
        
                      body.id = 'seo-shops-full-' + Date.now()
                      body.source = 'seo-shops-full-' + Date.now()
                      body.url = getUrl.protocol + "//" + hostUrl
        
                      if(getUrl.pathname === "/") {
                        body.takeScreenshots = 'no'
                        body.screenshotComment = ""
                      }
        
                      db.collection('Sites').doc(idSite).get()
                      .then(eleSite => {
                        // exist site
                        // db.collection('Crawls').where('status', '==', 'running').get()
                        // .then(queryCrawlRunning => {
                          // if(querySites.) {
                          var countSites = 0
                          var pageSizes = eleSite.data().pageSizes && eleSite.data().pageSizes.length ? eleSite.data().pageSizes : []
                          var pagesSite = eleSite.data().pages && eleSite.data().pages.length ? eleSite.data().pages : []
                          var filterInternals = pagesSite.filter(ele => ele.isSitemap === true)
                          var filterNoInternals = pagesSite.filter(ele => ele.isSitemap === false)
                          var filterProPages = filterNoInternals.filter(ele => ele.pro === true)
                          var filterNoProPages = filterNoInternals.filter(ele => ele.pro === false)
                          var filterInlinks = filterNoProPages
        
                          var condicionParaOrdenar = (pageA, pageB) => {
                            return pageB.inlinks.length - pageA.inlinks.length;
                          }
                          filterInlinks.sort(condicionParaOrdenar)
        
                          var arrayFoundPages = []
                          var arrayProPages = []
                          filterProPages.forEach(ele => {
                            var getUrl = new URL(ele.url)
                            if(getUrl.pathname !== '/') {
                              arrayProPages.push(ele.url)
                            }
        
                            arrayFoundPages.unshift(ele.url)
                          })
        
                          // filterInternals.forEach(ele => {
                          //   var filterPage = arrayFoundPages.filter(item => item === ele.url)
                          //   if(!filterPage.length) {
                          //     arrayFoundPages.push(ele.url)
                          //   }
                          // })
                          
                            db.collection('Crawls').where('WebsiteURL', '==', hostUrl).where('status', '==', 'running').get()
                            .then(queryCrawl => {
                              // only 1 loop
                              if(countSites === 0 && !queryCrawl.size) {
                                countSites += 1
                                var stringsArray = eleSite.data().stringSearch ? eleSite.data().stringSearch : []
                                var suspiciousTextArray = eleSite.data().suspiciousText ? eleSite.data().suspiciousText : []
                                var arrayStringsSuspicious = []
                                stringsArray.forEach(ele => {
                                  arrayStringsSuspicious.push(ele)
                                })

                                suspiciousTextArray.forEach(ele => {
                                  arrayStringsSuspicious.push(ele)
                                })

                                var getConfigCrawl = localStorage.getItem('configCrawl')
                                var limitCrawl = 100
                                var configCrawlType = 'JS FREE'
                                var configCrawlPages = 'FREE'
                                var setCrawl = false
                                if(getConfigCrawl) {
                                  setCrawl = true
                                  var parseConfig = JSON.parse(getConfigCrawl)
                                  var logicTime = moment(parseConfig.date)
                                  var actualTime = moment(new Date())
                                  var diffSeconds = actualTime.diff(logicTime, 'seconds');
                                  if(diffSeconds < 600) {
                                    limitCrawl = parseConfig.pages
                                    configCrawlType = parseConfig.crawl
                                    configCrawlPages = parseConfig.pages === 25 ? 'GUESS' : parseConfig.pages === 100 ? 'FREE' : parseConfig.pages === 600 ? 'LICENSE' : 'FREE'
                                  }
                                  var getConfigCrawl = localStorage.removeItem('configCrawl')
                                }
                                
                                var limitCrawlSetSite = eleSite.data().limitCrawl ? eleSite.data().limitCrawl : 0
                                if(limitCrawlSetSite) {
                                  if(configCrawlPages === 'GUESS' && limitCrawlSetSite < 25) {
                                    limitCrawl = limitCrawlSetSite
                                  } else if(configCrawlPages === 'FREE' && limitCrawlSetSite < 100) {
                                    limitCrawl = limitCrawlSetSite
                                  } else if(configCrawlPages === 'LICENSE') {
                                    limitCrawl = limitCrawlSetSite
                                  }
                                }
        
                                // add Crawl
                                db.collection('Crawls').add({
                                  'IDcrawl': body.id,
                                  'dateStart': Date.now(),
                                  'dateStartFormat': new Date(),
                                  'dateEnd': null,
                                  'crawlDuration': null,
                                  'status': 'running',
                                  'crawltype': 'full',
                                  'ignoreindexnofollow': 'yes',
                                  'Requests': 1,
                                  'SiteID': eleSite.id,
                                  'WebsiteURL': hostUrl,
                                  'blockedByRobots': false,
                                  'logicRequests': 1,
                                  'proPages': [urlBody],
                                  'totalErrors': 0,
                                  'limitCrawl': limitCrawl,
                                  'stringSearch': arrayStringsSuspicious,
                                  'founds': arrayFoundPages,
                                  'requests': [],
                                  'pages': [],
                                  'inlinks': [],
                                  'external': [],
                                  'pageSizes': pageSizes,
                                  'updated': Date.now(),
                                  'plus1k': false,
                                  'errorsPages': [],
                                  'configCrawlType': 'JS FREE',
                                  'configCrawlPages': 'FREE',
                                  'errorsTypePage': [],
                                  'timeoutPages': 0,
                                  'errorsCategory': {}
                                })
                                .then((docRefCrawl) => {
                                  var URLsfound = [
                                    {
                                      'id': null,
                                      'title': null,
                                      'runPosition': 1,
                                      'lastRequest': null,
                                      'pro': true,
                                      'inlinks': [],
                                      'status': null,
                                      'redirectTo': null,
                                      'blockedByRobots': false,
                                      // 'totalErrors': 0,
        
                                      'crawleable': true,
                                      'crawled': false,
                                      'crawlerInvoke': true,
                                      'url': urlBody
                                      
                                    },
                                    // {
                                    //   'id': null,
                                    //   'title': null,
                                    //   'runPosition': 2,
                                    //   'lastRequest': null,
                                    //   'pro': false,
                                    //   'inlinks': [],
                                    //   'status': null,
                                    //   'redirectTo': null,
                                    //   'blockedByRobots': false,
        
                                    //   'crawleable': true,
                                    //   'crawled': false,
                                    //   'crawlerInvoke': true,
                                    //   'url': urlConcat + "site-map.xml"
                                    // },
                                    {
                                      'id': null,
                                      'title': null,
                                      'runPosition': 2,
                                      'lastRequest': null,
                                      'pro': false,
                                      'inlinks': [],
                                      'status': null,
                                      'redirectTo': null,
                                      'blockedByRobots': false,
        
                                      'crawleable': true,
                                      'crawled': false,
                                      'crawlerInvoke': true,
                                      'url': urlConcat + "sitemap.xml"
                                    },
                                    // {
                                    //   'id': null,
                                    //   'title': null,
                                    //   'runPosition': 4,
                                    //   'lastRequest': null,
                                    //   'pro': false,
                                    //   'inlinks': [],
                                    //   'status': null,
                                    //   'redirectTo': null,
                                    //   'blockedByRobots': false,
        
                                    //   'crawleable': true,
                                    //   'crawled': false,
                                    //   'crawlerInvoke': true,
                                    //   'url': urlConcat + "page-sitemap.xml"
                                    // }
                                  ]

                                  db.collection('Sites').doc(eleSite.id).update({
                                    mostRecentCrawl: docRefCrawl.id,
                                    n_log_crawls: eleSite.data().n_log_crawls ? firebase.firestore.FieldValue.increment(1) : 1,
                                    totalErrors: 0
                                  })
        
                                  // add pages crawl
                                  URLsfound.forEach(eleC => {
                                    db.collection('Crawls').doc(docRefCrawl.id).update({
                                      requests: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                    })
        
                                    db.collection('Crawls').doc(docRefCrawl.id).update({
                                      founds: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                    })
        
                                    db.collection('Crawls').doc(docRefCrawl.id).update({
                                      pages: firebase.firestore.FieldValue.arrayUnion({
                                        id: '',
                                        title: '',
                                        runPosition: null,
                                        lastRequest: null,
                                        pro: false,
                                        inlinks: [],
                                        status: null,
                                        redirectTo: '',
                                        blockedByRobots: false,
                                        crawled: true,
                                        crawleable: true,
                                        url: eleC.url,
                                        isSitemap: false,
                                        hasRun: false,
                                        active: false,
                                        size: 999999999,
                                        tries: 1,
                                        tryTimestamp: Date.now()
                                      })
                                    })
                                    .catch(e => console.log('Error update master page: ', e.message))
                                  })
        
                                  // LOGIC RUN INVOKE CRAWL
                                  var protocolSite = ''
                                  var homepageSite = ''
                                  if(eleSite.data().stringSearch.length > 0) {
                                    body.stringSearch = eleSite.data().stringSearch
                                  }
                                  protocolSite = eleSite.data().prot ? eleSite.data().prot : getUrl.protocol
                                  body.url = protocolSite + "//" + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                                  homepageSite = eleSite.data().homepage ? eleSite.data().homepage : ''
                                  body.takeScreenshots = 'no'
                                  body.triggeredBy = eleSite.id
                                  body.source = docRefCrawl.id
                                  body.type = 'only-urls'
                                  body.useFastCrawler = 'no'
                                  body.typePage = 'homeXML'
                                  body.parseAsTextContent = 'yes'
        
                                  var urlOrigin = body.url
                                  
                                  // var config = {
                                  //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                  //   "method": "POST",
                                  //   "timeout": 0,
                                  //   "headers": {
                                  //     "Content-Type": "application/json",
                                  //     "Accept": "application/json"
                                  //   },
                                  //   "data": JSON.stringify(body),
                                  // };

                                  var config = {
                                    method: 'POST',
                                    url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                    maxBodyLength: Infinity,
                                    timeout: 0,
                                    headers: {
                                      'Content-Type': 'application/json',
                                      'Accept': 'application/json'
                                    },
                                    data: JSON.stringify(body)
                                  };
                
                                  this._vm.axios(config)
                                  .then((response) => {
                                    body.url = urlOrigin
                                    body.takeScreenshots = 'no'
                                    body.type = 'full'
                                    body.typePage = 'normal'
                                    // console.log(response.data)
                                    console.log('invoke home')
                                  })
                                  .catch(e => {
                                    console.log(e)
                                  })
                                  
                                  // run crawl three initials xml
                                  // body.typePage = 'normal'
                                  var atomicBody = body
                                  var countInvocked = 0
                                  // let arraySites = [atomicBody.url + "/site-map.xml", atomicBody.url + "/sitemap.xml", atomicBody.url + "/page-sitemap.xml"]
                                  let arraySites = [atomicBody.url + (atomicBody.url.endsWith('/') ? 'sitemap.xml' : '/sitemap.xml')]
                                  // let arraySites = []
                                  arraySites.forEach(res => {
                                    var objXMLCrawl = {
                                      forceNotFound: 'yes',
                                      id: body.id,
                                      ignoreIndexNoFollow: 'yes',
                                      pageSize: 999999999,
                                      screenshotComment: '',
                                      screenshotType: 'test',
                                      source: body.source,
                                      stringSearch: body.stringSearch,
                                      takeScreenshots: 'no',
                                      triggeredBy: body.triggeredBy,
                                      type: 'only-urls',
                                      url: res,
                                      useFastCrawler: 'no',
                                      typePage: 'normal',
                                      parseAsTextContent: 'no'
                                    }
                                    atomicBody.url = res
                                    atomicBody.type = 'only-urls'
                                    atomicBody.takeScreenshots = 'no'
                                    atomicBody.typePage = 'normal'
                                    
                                    // var config = {
                                    //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                    //   "method": "POST",
                                    //   "timeout": 0,
                                    //   "headers": {
                                    //     "Content-Type": "application/json",
                                    //     "Accept": "application/json"
                                    //   },
                                    //   "data": JSON.stringify(atomicBody),
                                    // };
                                    body.url = urlOrigin
                                    body.type = 'full'
        
                                    var config = {
                                      method: 'POST',
                                      url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                      maxBodyLength: Infinity,
                                      timeout: 0,
                                      headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json'
                                      },
                                      data: JSON.stringify(objXMLCrawl)
                                    };
                  
                                    this._vm.axios(config)
                                    .then((response) => {
                                      countInvocked += 1
                                      // body.url = urlOrigin
                                      // if(countInvocked === 3) {
                                        // console.log(response.data)
                                        console.log('invoke init xml')
                                        body.stringSearch = ["PHP ERROR",'Lorem','Ipsum']
                                        body.takeScreenshots = 'no'
                                        body.screenshotComment = ""
                                        body.url = urlOrigin
                                      // }
                                    })
                                    .catch(e => {
                                      console.log(e)
                                    })
                                  })
        
                                  db.collection('Sites').doc(eleSite.id).update({
                                    mostRecentCrawlStatus: 'running',
                                    mostRecentCrawl: docRefCrawl.id,
                                    mostRecentCrawlId: body.id,
                                    pages: [],
                                    // pageSizes: [],
                                    externalLinks: [],
                                    plus1k: false
                                  })
                                  .catch(e => console.log('Error update site: ', e))
        
                                  db.collection('Sites_cache').doc(eleSite.id).update({
                                    mostRecentCrawlStatus: 'running',
                                    mostRecentCrawl: docRefCrawl.id,
                                    mostRecentCrawlId: body.id,
                                    pages: [],
                                    inlinks: [],
                                    plus1k: false
                                  })
                                  .catch(e => console.log('Error update site: ', e))
        
                                  URLsfound.forEach(eleC => {
                                    var objCache = {}
                                    objCache.id = null
                                    objCache.title = null
                                    objCache.url = eleC.url
                                    objCache.inlinks = null
                                    objCache.inlinksNumber = null
                                    objCache.pro = false
                                    objCache.status = null
                                    objCache.redirectTo = null
                                    objCache.blockedByRobots = null
                                    objCache.totalErrors = 0
                                    objCache.errorByType = {}
                                    objCache.isSitemap = eleC.url.includes('.xml') ? true : false
                                    db.collection('Sites_cache').doc(eleSite.id).update({
                                      pages: firebase.firestore.FieldValue.arrayUnion(objCache)
                                    })
        
                                    var objPageCollection = {
                                      id: null,
                                      title: null,
                                      url: eleC.url,
                                      inlinks: [],
                                      inlinksNumber: 0,
                                      pro: false,
                                      // status: itemPage.status,
                                      status: null,
                                      redirectTo: null,
                                      blockedByRobots: null,
                                      totalErrors: 0,
                                      errorByType: {},
                                      active: false,
                                      crawleable: true,
                                      size: null,
                                      isSitemap: eleC.url.includes('.xml') ? true : false
                                    }
        
                                    db.collection('Sites').doc(eleSite.id).collection('pages').where('url', '==', eleC.url).limit(1).get()
                                    .then(docSite => {
                                      if(docSite.size) {
                                        var counterLoop = 0
                                        docSite.forEach(ele => {
                                          if(counterLoop === 0) {
                                            db.collection('Sites').doc(eleSite.id).collection('pages').doc(ele.id).update(objPageCollection)
                                          }
                                        })
                                      } else {
                                        db.collection('Sites').doc(eleSite.id).collection('pages').add(objPageCollection)
                                        .then((docRefSite) => {
                                            db.collection('Sites').doc(eleSite.id).collection('pages').doc(docRefSite.id).update({
                                              id: docRefSite.id
                                            })
                                        })
                                      }
                                    })
                                  })
                                })
                                .catch(e => console.log('Error add crawl site: ', e))
                              } else if(queryCrawl.size) {
                                console.log('Crawl running already!')
                              }
                            })
                            .catch(e => console.log(e.message))
                        // })
                      })
                      .catch(e => {
                        console.log('Error query sites host: ', e)
                      })
                    }

                    db.collection('Projects').doc(docRef.id).update({
                      numberSites: 1,
                    })

                    db.collection('Users').doc(payload.owned.uid).collection('projects').doc(docRef.id).update({
                      numberSites: 1,
                    })
      
                    setTimeout(() => {
                      db.collection('Users').doc(payload.owned.uid).get()
                      .then(docUser => {
                        var objnewSiteMail = {
                          nameUser: docUser.data().nombre ? docUser.data().nombre : docUser.data().email,
                          email: docUser.data().email,
                          existUser: true,
                          urlSite: URLdomainWithPath,
                          idsite: idSite,
                          idproject: docRef.id
                        }

                        // update seocloud-dcdfb
                        var confignewSiteMail = {
                          method: 'POST',
                          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/newSiteMail',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          data: JSON.stringify(objnewSiteMail),
                        }

                        this._vm.axios(confignewSiteMail)
                        .then(() => {
                          console.log('email sended')
                        })
                        .catch(error => {
                          console.log(error)
                        })
                      })
                      
                      this._vm.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Site created successfully`,
                          icon: 'CoffeeIcon',
                          variant: 'success'
                        },
                      })
                      router.push({ name: 'project-sites', params: {id: docRef.id, name: payload.projectName, own: 'me'} })
                    }, 1500)
                  } else {
                    db.collection('Sites').add({
                      'SiteID': '',
                      'name': payload.siteName,
                      'n': payload.siteName ? payload.siteName.toLowerCase() : '',
                      'WebsiteURL': URLdomainWithPath,
                      'homepage': urlBodyx,
                      thumbnail: existThumbnail,
                      'Blockedbyrobot': 'no',
                      // 'pagesFound': '',
                      'proPages': [],
                      'asignatedProPages': false,
                      'mostRecentCrawl': '',
                      'mostRecentCrawlStatus': '',
                      'totalErrors': 0,
                      'errorsCategory': {},
                      'claimed': false,
                      'owner': '',
                      'plan': '',
                      'paidRecurrence': '',
                      'totalPages': 0,
                      'createdBy': payload.owned.uid,
                      'created': Date.now(),
                      'registrationDay': moment().format('dddd'),

                      'owners': [],
                      'limitCrawl': 600,
                      'prot': URLprotocol,
                      'domainPrefix': urlPrefix,
                      'stringSearch': ['PHP ERROR','Lorem','Ipsum','Consectetur'],
                      'totalSuccessCrawl': 0,
                      'externalLinks': [],
                      'pageSizes': [],
                      'projects': [docRef.id],
                      'plus1k': false,
                      'teamMembers': [],
                      'errorsPages': [],
                      'suspiciousText': ['viagra'],
                      'n_guess_crawls': 0,
                      'n_log_crawls': 0
                      
                      // 'FirstFound': Date.now(),
                      // 'sizeValidate': true,
                      // 'Mostrecentfullcrawl': this.body.id,
                      // 'Mostrecentfullcrawldatestart': Date.now(),
                      // 'Mostrecentfullcrawldateend': null,
                      // 'Mostrecentfullcrawlsuccessid': null,
                      // 'Mostrecentfullcrawlsuccess': false,
                      // 'Mostrecentcrawltype': bodySite.type ? bodySite.type : 'full',
                      // 'Mostrecentsinglecrawl': null,
                      // 'MostrecentsinglecrawlURL': null,
                      // 'Mostrecentsinglecrawldate': null
                    })
                    .then(docRefSite => {
                      var dataSrc = JSON.stringify({
                        "website": urlBodyx
                      });
                
                      var configSrc = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: 'https://sslcheckerapi-h4klmyt43a-uc.a.run.app/screenshots/register',
                        headers: { 
                          'Content-Type': 'application/json'
                        },
                        data : dataSrc
                      };
                
                      this._vm.axios(configSrc)
                      .then((response) => {
                        console.log('register screenshots: ', response)
                      })
                      .catch((error) => {
                        console.error('Error register screenshot: ', error)
                      });

                      // update Sites id
                      db.collection('Sites').doc(docRefSite.id).update({
                        SiteID: docRefSite.id,
                        teamMembers: firebase.firestore.FieldValue.arrayUnion(payload.owned.uid)
                      })
                      .catch(e => console.log('Error update ref site: ', e))

                      db.collection('Users').doc(payload.owned.uid).get()
                      .then(docUser => {
                        var objnewSiteMail = {
                          nameUser: docUser.data().nombre ? docUser.data().nombre : docUser.data().email,
                          email: docUser.data().email,
                          existUser: true,
                          urlSite: URLdomainWithPath,
                          idsite: docRef.id,
                          idproject: docRefSite.id
                        }

                        // update seocloud-dcdfb
                        var confignewSiteMail = {
                          method: 'POST',
                          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/newSiteMail',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          data: JSON.stringify(objnewSiteMail),
                        }

                        this._vm.axios(confignewSiteMail)
                        .then(() => {
                          console.log('email sended')
                        })
                        .catch(error => {
                          console.log(error)
                        })
                      })

                      if(docRefSite.id) {
                        var body = {
                          url: '',
                          type: 'full',
                          forceNotFound: 'yes',
                          pageSize: 999999999,
                          id: 'seo-shops-full-' + Date.now(),
                          takeScreenshots: 'no',
                          source: 'seo-cloud-full-' + Date.now(),
                          triggeredBy: 'test triggered',
                          screenshotType: 'test',
                          screenshotComment: '',
                          stringSearch: ["PHP ERROR",'Lorem','Ipsum'],
                          ignoreIndexNoFollow: "yes"
                        }
                        var getUrl = new URL(site);
                        var hostUrl = getUrl.host;
                        var httpsString = getUrl.protocol + '//';
                        var urlConcat = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                        var urlBody = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                        var ultimoCaracterUrl = urlConcat.charAt(urlConcat.length - 1);
                        if(ultimoCaracterUrl != "/") {
                          urlConcat += "/";
                        }
          
                        body.id = 'seo-shops-full-' + Date.now()
                        body.source = 'seo-shops-full-' + Date.now()
                        body.url = getUrl.protocol + "//" + hostUrl
          
                        if(getUrl.pathname === "/") {
                          body.takeScreenshots = 'no'
                          body.screenshotComment = ""
                        }
          
                        db.collection('Sites').doc(docRefSite.id).get()
                        .then(eleSite => {
                          // exist site
                          // db.collection('Crawls').where('status', '==', 'running').get()
                          // .then(queryCrawlRunning => {
                            // if(querySites.) {
                            var countSites = 0
                            var pageSizes = eleSite.data().pageSizes && eleSite.data().pageSizes.length ? eleSite.data().pageSizes : []
                            var pagesSite = eleSite.data().pages && eleSite.data().pages.length ? eleSite.data().pages : []
                            var filterInternals = pagesSite.filter(ele => ele.isSitemap === true)
                            var filterNoInternals = pagesSite.filter(ele => ele.isSitemap === false)
                            var filterProPages = filterNoInternals.filter(ele => ele.pro === true)
                            var filterNoProPages = filterNoInternals.filter(ele => ele.pro === false)
                            var filterInlinks = filterNoProPages
          
                            var condicionParaOrdenar = (pageA, pageB) => {
                              return pageB.inlinks.length - pageA.inlinks.length;
                            }
                            filterInlinks.sort(condicionParaOrdenar)
          
                            var arrayFoundPages = []
                            var arrayProPages = []
                            filterProPages.forEach(ele => {
                              var getUrl = new URL(ele.url)
                              if(getUrl.pathname !== '/') {
                                arrayProPages.push(ele.url)
                              }
          
                              arrayFoundPages.unshift(ele.url)
                            })
          
                            // filterInternals.forEach(ele => {
                            //   var filterPage = arrayFoundPages.filter(item => item === ele.url)
                            //   if(!filterPage.length) {
                            //     arrayFoundPages.push(ele.url)
                            //   }
                            // })
                            
                              db.collection('Crawls').where('WebsiteURL', '==', hostUrl).where('status', '==', 'running').get()
                              .then(queryCrawl => {
                                // only 1 loop
                                if(countSites === 0 && !queryCrawl.size) {
                                  countSites += 1
                                  var stringsArray = eleSite.data().stringSearch ? eleSite.data().stringSearch : []
                                  var suspiciousTextArray = eleSite.data().suspiciousText ? eleSite.data().suspiciousText : []
                                  var arrayStringsSuspicious = []
                                  stringsArray.forEach(ele => {
                                    arrayStringsSuspicious.push(ele)
                                  })

                                  suspiciousTextArray.forEach(ele => {
                                    arrayStringsSuspicious.push(ele)
                                  })

                                  var getConfigCrawl = localStorage.getItem('configCrawl')
                                  var limitCrawl = 100
                                  var configCrawlType = 'JS FREE'
                                  var configCrawlPages = 'FREE'
                                  var setCrawl = false
                                  if(getConfigCrawl) {
                                    setCrawl = true
                                    var parseConfig = JSON.parse(getConfigCrawl)
                                    var logicTime = moment(parseConfig.date)
                                    var actualTime = moment(new Date())
                                    var diffSeconds = actualTime.diff(logicTime, 'seconds');
                                    if(diffSeconds < 600) {
                                      limitCrawl = parseConfig.pages
                                      configCrawlType = parseConfig.crawl
                                      configCrawlPages = parseConfig.pages === 25 ? 'GUESS' : parseConfig.pages === 100 ? 'FREE' : parseConfig.pages === 600 ? 'LICENSE' : 'FREE'
                                    }
                                    var getConfigCrawl = localStorage.removeItem('configCrawl')
                                  }
                                  
                                  var limitCrawlSetSite = eleSite.data().limitCrawl ? eleSite.data().limitCrawl : 0
                                  if(limitCrawlSetSite) {
                                    if(configCrawlPages === 'GUESS' && limitCrawlSetSite < 25) {
                                      limitCrawl = limitCrawlSetSite
                                    } else if(configCrawlPages === 'FREE' && limitCrawlSetSite < 100) {
                                      limitCrawl = limitCrawlSetSite
                                    } else if(configCrawlPages === 'LICENSE') {
                                      limitCrawl = limitCrawlSetSite
                                    }
                                  }
          
                                  // add Crawl
                                  db.collection('Crawls').add({
                                    'IDcrawl': body.id,
                                    'dateStart': Date.now(),
                                    'dateStartFormat': new Date(),
                                    'dateEnd': null,
                                    'crawlDuration': null,
                                    'status': 'running',
                                    'crawltype': 'full',
                                    'ignoreindexnofollow': 'yes',
                                    'Requests': 1,
                                    'SiteID': eleSite.id,
                                    'WebsiteURL': hostUrl,
                                    'blockedByRobots': false,
                                    'logicRequests': 1,
                                    'proPages': [urlBody],
                                    'totalErrors': 0,
                                    'limitCrawl': limitCrawl,
                                    'stringSearch': arrayStringsSuspicious,
                                    'founds': arrayFoundPages,
                                    'requests': [],
                                    'pages': [],
                                    'inlinks': [],
                                    'external': [],
                                    'pageSizes': pageSizes,
                                    'updated': Date.now(),
                                    'plus1k': false,
                                    'errorsPages': [],
                                    'configCrawlType': 'JS FREE',
                                    'configCrawlPages': 'FREE',
                                    'errorsTypePage': [],
                                    'timeoutPages': 0,
                                    'errorsCategory': {}
                                  })
                                  .then((docRefCrawl) => {
                                    var URLsfound = [
                                      {
                                        'id': null,
                                        'title': null,
                                        'runPosition': 1,
                                        'lastRequest': null,
                                        'pro': true,
                                        'inlinks': [],
                                        'status': null,
                                        'redirectTo': null,
                                        'blockedByRobots': false,
                                        // 'totalErrors': 0,
          
                                        'crawleable': true,
                                        'crawled': false,
                                        'crawlerInvoke': true,
                                        'url': urlBody
                                        
                                      },
                                      // {
                                      //   'id': null,
                                      //   'title': null,
                                      //   'runPosition': 2,
                                      //   'lastRequest': null,
                                      //   'pro': false,
                                      //   'inlinks': [],
                                      //   'status': null,
                                      //   'redirectTo': null,
                                      //   'blockedByRobots': false,
          
                                      //   'crawleable': true,
                                      //   'crawled': false,
                                      //   'crawlerInvoke': true,
                                      //   'url': urlConcat + "site-map.xml"
                                      // },
                                      {
                                        'id': null,
                                        'title': null,
                                        'runPosition': 2,
                                        'lastRequest': null,
                                        'pro': false,
                                        'inlinks': [],
                                        'status': null,
                                        'redirectTo': null,
                                        'blockedByRobots': false,
          
                                        'crawleable': true,
                                        'crawled': false,
                                        'crawlerInvoke': true,
                                        'url': urlConcat + "sitemap.xml"
                                      },
                                      // {
                                      //   'id': null,
                                      //   'title': null,
                                      //   'runPosition': 4,
                                      //   'lastRequest': null,
                                      //   'pro': false,
                                      //   'inlinks': [],
                                      //   'status': null,
                                      //   'redirectTo': null,
                                      //   'blockedByRobots': false,
          
                                      //   'crawleable': true,
                                      //   'crawled': false,
                                      //   'crawlerInvoke': true,
                                      //   'url': urlConcat + "page-sitemap.xml"
                                      // }
                                    ]

                                    db.collection('Sites').doc(eleSite.id).update({
                                      mostRecentCrawl: docRefCrawl.id,
                                      n_log_crawls: eleSite.data().n_log_crawls ? firebase.firestore.FieldValue.increment(1) : 1,
                                      totalErrors: 0
                                    })
          
                                    // add pages crawl
                                    URLsfound.forEach(eleC => {
                                      db.collection('Crawls').doc(docRefCrawl.id).update({
                                        requests: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                      })
          
                                      db.collection('Crawls').doc(docRefCrawl.id).update({
                                        founds: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                                      })
          
                                      db.collection('Crawls').doc(docRefCrawl.id).update({
                                        pages: firebase.firestore.FieldValue.arrayUnion({
                                          id: '',
                                          title: '',
                                          runPosition: null,
                                          lastRequest: null,
                                          pro: false,
                                          inlinks: [],
                                          status: null,
                                          redirectTo: '',
                                          blockedByRobots: false,
                                          crawled: true,
                                          crawleable: true,
                                          url: eleC.url,
                                          isSitemap: false,
                                          hasRun: false,
                                          active: false,
                                          size: 999999999,
                                          tries: 1,
                                          tryTimestamp: Date.now()
                                        })
                                      })
                                      .catch(e => console.log('Error update master page: ', e.message))
                                    })
          
                                    // LOGIC RUN INVOKE CRAWL
                                    var protocolSite = ''
                                    var homepageSite = ''
                                    if(eleSite.data().stringSearch.length > 0) {
                                      body.stringSearch = eleSite.data().stringSearch
                                    }
                                    protocolSite = eleSite.data().prot ? eleSite.data().prot : getUrl.protocol
                                    body.url = protocolSite + "//" + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                                    homepageSite = eleSite.data().homepage ? eleSite.data().homepage : ''
                                    body.takeScreenshots = 'no'
                                    body.triggeredBy = eleSite.id
                                    body.source = docRefCrawl.id
                                    body.type = 'only-urls'
                                    body.useFastCrawler = 'no'
                                    body.typePage = 'homeXML'
                                    body.parseAsTextContent = 'yes'
          
                                    var urlOrigin = body.url
                                    
                                    // var config = {
                                    //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                    //   "method": "POST",
                                    //   "timeout": 0,
                                    //   "headers": {
                                    //     "Content-Type": "application/json",
                                    //     "Accept": "application/json"
                                    //   },
                                    //   "data": JSON.stringify(body),
                                    // };
          
                                    var config = {
                                      method: 'POST',
                                      url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                      maxBodyLength: Infinity,
                                      timeout: 0,
                                      headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json'
                                      },
                                      data: JSON.stringify(body)
                                    };
                  
                                    this._vm.axios(config)
                                    .then((response) => {
                                      body.url = urlOrigin
                                      body.takeScreenshots = 'no'
                                      body.type = 'full'
                                      body.typePage = 'normal'
                                      // console.log(response.data)
                                      console.log('invoke home')
                                    })
                                    .catch(e => {
                                      console.log(e)
                                    })
                                    
                                    // run crawl three initials xml
                                    // body.typePage = 'normal'
                                    var atomicBody = body
                                    var countInvocked = 0
                                    // let arraySites = [atomicBody.url + "/site-map.xml", atomicBody.url + "/sitemap.xml", atomicBody.url + "/page-sitemap.xml"]
                                    let arraySites = [atomicBody.url + (atomicBody.url.endsWith('/') ? 'sitemap.xml' : '/sitemap.xml')]
                                    // let arraySites = []
                                    arraySites.forEach(res => {
                                      var objXMLCrawl = {
                                        forceNotFound: 'yes',
                                        id: body.id,
                                        ignoreIndexNoFollow: 'yes',
                                        pageSize: 999999999,
                                        screenshotComment: '',
                                        screenshotType: 'test',
                                        source: body.source,
                                        stringSearch: body.stringSearch,
                                        takeScreenshots: 'no',
                                        triggeredBy: body.triggeredBy,
                                        type: 'only-urls',
                                        url: res,
                                        useFastCrawler: 'no',
                                        typePage: 'normal',
                                        parseAsTextContent: 'no'
                                      }
                                      atomicBody.url = res
                                      atomicBody.type = 'only-urls'
                                      atomicBody.takeScreenshots = 'no'
                                      atomicBody.typePage = 'normal'
                                      
                                      // var config = {
                                      //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                                      //   "method": "POST",
                                      //   "timeout": 0,
                                      //   "headers": {
                                      //     "Content-Type": "application/json",
                                      //     "Accept": "application/json"
                                      //   },
                                      //   "data": JSON.stringify(atomicBody),
                                      // };
                                      body.url = urlOrigin
                                      body.type = 'full'
          
                                      var config = {
                                        method: 'POST',
                                        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                        maxBodyLength: Infinity,
                                        timeout: 0,
                                        headers: {
                                          'Content-Type': 'application/json',
                                          'Accept': 'application/json'
                                        },
                                        data: JSON.stringify(objXMLCrawl)
                                      };
                    
                                      this._vm.axios(config)
                                      .then((response) => {
                                        countInvocked += 1
                                        // body.url = urlOrigin
                                        // if(countInvocked === 3) {
                                          // console.log(response.data)
                                          console.log('invoke init xml')
                                          body.stringSearch = ["PHP ERROR",'Lorem','Ipsum']
                                          body.takeScreenshots = 'no'
                                          body.screenshotComment = ""
                                          body.url = urlOrigin
                                        // }
                                      })
                                      .catch(e => {
                                        console.log(e)
                                      })
                                    })
          
                                    db.collection('Sites').doc(eleSite.id).update({
                                      mostRecentCrawlStatus: 'running',
                                      mostRecentCrawl: docRefCrawl.id,
                                      mostRecentCrawlId: body.id,
                                      pages: [],
                                      // pageSizes: [],
                                      externalLinks: [],
                                      plus1k: false
                                    })
                                    .catch(e => console.log('Error update site: ', e))
          
                                    db.collection('Sites_cache').doc(eleSite.id).update({
                                      mostRecentCrawlStatus: 'running',
                                      mostRecentCrawl: docRefCrawl.id,
                                      mostRecentCrawlId: body.id,
                                      pages: [],
                                      inlinks: [],
                                      plus1k: false
                                    })
                                    .catch(e => console.log('Error update site: ', e))
          
                                    URLsfound.forEach(eleC => {
                                      var objCache = {}
                                      objCache.id = null
                                      objCache.title = null
                                      objCache.url = eleC.url
                                      objCache.inlinks = null
                                      objCache.inlinksNumber = null
                                      objCache.pro = false
                                      objCache.status = null
                                      objCache.redirectTo = null
                                      objCache.blockedByRobots = null
                                      objCache.totalErrors = 0
                                      objCache.errorByType = {}
                                      objCache.isSitemap = eleC.url.includes('.xml') ? true : false
                                      db.collection('Sites_cache').doc(eleSite.id).update({
                                        pages: firebase.firestore.FieldValue.arrayUnion(objCache)
                                      })
          
                                      var objPageCollection = {
                                        id: null,
                                        title: null,
                                        url: eleC.url,
                                        inlinks: [],
                                        inlinksNumber: 0,
                                        pro: false,
                                        // status: itemPage.status,
                                        status: null,
                                        redirectTo: null,
                                        blockedByRobots: null,
                                        totalErrors: 0,
                                        errorByType: {},
                                        active: false,
                                        crawleable: true,
                                        size: null,
                                        isSitemap: eleC.url.includes('.xml') ? true : false
                                      }
          
                                      db.collection('Sites').doc(eleSite.id).collection('pages').where('url', '==', eleC.url).limit(1).get()
                                      .then(docSite => {
                                        if(docSite.size) {
                                          var counterLoop = 0
                                          docSite.forEach(ele => {
                                            if(counterLoop === 0) {
                                              db.collection('Sites').doc(eleSite.id).collection('pages').doc(ele.id).update(objPageCollection)
                                            }
                                          })
                                        } else {
                                          db.collection('Sites').doc(eleSite.id).collection('pages').add(objPageCollection)
                                          .then((docRefSite) => {
                                              db.collection('Sites').doc(eleSite.id).collection('pages').doc(docRefSite.id).update({
                                                id: docRefSite.id
                                              })
                                          })
                                        }
                                      })
                                    })
                                  })
                                  .catch(e => console.log('Error add crawl site: ', e))
                                } else if(queryCrawl.size) {
                                  console.log('Crawl running already!')
                                }
                              })
                              .catch(e => console.log(e.message))
                          // })
                        })
                        .catch(e => {
                          console.log('Error query sites host: ', e)
                        })
                      }

                      // var objPage = {}
                      // objPage.id = null
                      // objPage.title = null
                      // objPage.url = urlBody
                      // objPage.inlinks = []
                      // objPage.inlinksNumber = 0
                      // objPage.size = null
                      // objPage.pro = false
                      // objPage.status = null
                      // objPage.redirectTo = null
                      // objPage.blockedByRobots = null
                      // objPage.totalErrors = 0
                      // objPage.errorByType = {}
                      // objPage.active = null
                      // objPage.crawleable = true
                      // objPage.isSitemap = false
                      // objPage.lastRequest = null
                      // db.collection('Sites').doc(docRefSite.id).update({
                      //   pages: firebase.firestore.FieldValue.arrayUnion(objPage),
                      //   proPages: firebase.firestore.FieldValue.arrayUnion(urlBody)
                      // })

                      // var objCache = {}
                      // objCache.id = null
                      // objCache.title = null
                      // objCache.url = urlBody
                      // objCache.inlinks = null
                      // objCache.inlinksNumber = null
                      // objCache.pro = false
                      // objCache.status = null
                      // objCache.redirectTo = null
                      // objCache.blockedByRobots = null
                      // objCache.totalErrors = 0
                      // objCache.errorByType = {}
                      // objCache.isSitemap = false
                      // db.collection('Sites_cache').doc(docRefSite.id).update({
                      //   pages: firebase.firestore.FieldValue.arrayUnion(objCache),
                      //   proPages: firebase.firestore.FieldValue.arrayUnion(urlBody)
                      // })

                      db.collection('Sites_cache').doc(docRefSite.id).set({
                        'SiteID': docRefSite.id,
                        'name': payload.siteName,
                        'n': payload.siteName ? payload.siteName.toLowerCase() : '',
                        'WebsiteURL': URLdomainWithPath,
                        'homepage': urlBodyx,
                        thumbnail: existThumbnail,
                        'Blockedbyrobot': 'no',
                        // 'pagesFound': '',
                        'proPages': [],
                        inlinks: [],
                        'asignatedProPages': false,
                        'mostRecentCrawl': '',
                        'mostRecentCrawlStatus': '',
                        'totalErrors': 0,
                        'errorsCategory': {},
                        'claimed': false,
                        'owner': '',
                        'plan': '',
                        'paidRecurrence': '',
                        'totalPages': 0,
                        'createdBy': payload.owned.uid,
                        'projects': [docRef.id],
                        'plus1k': false
                      })
                      .catch(e => console.log('Error add site cache: ', e))
                      
                      // add site to project
                      db.collection('Projects').doc(docRef.id).collection('sites').doc(docRefSite.id).set({
                        'SiteID': docRefSite.id,
                        'WebsiteURL': URLdomainWithPath,
                        'homepage': urlBodyx,
                        'name': payload.siteName,
                        'n': payload.siteName ? payload.siteName.toLowerCase() : '',
                        thumbnail: existThumbnail,
                        'totalErrors': 0,
                        'plan': '',
                        'paidRecurrence': '',
                        'claimed': false,
                        'owner': '',
                        'status': 'active',
                        // projectId: docRef.id,
                      })
                      .catch(error => {
                        console.error(error)
                      })

                      db.collection('Projects').doc(docRef.id).update({
                        numberSites: 1,
                      })

                      db.collection('Users').doc(payload.owned.uid).collection('projects').doc(docRef.id).update({
                        numberSites: 1,
                      })

                      // create teamMembers
                      if(payload.projectInvitations.length >= 1) {
                        // const members = []
                        // const membersEmail = []
                        let member = ''
                        payload.projectInvitations.forEach(item => {
                          if(item.email.includes('@') && item.email.includes('.')) {
                            const email = item.email.trim()
                            db.collection('Users').where('email', '==', email).get()
                            .then(queryUser => {
                              // verify if user already exist, to add uid
                              var userID = ''
                              if(queryUser.size >= 1) {
                                queryUser.forEach(userItem => {
                                  userID = userItem.id
                                  // members.push({
                                  //   uid: userItem.id,
                                  //   email,
                                  //   perm: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                                  //   imgU: userItem.data().foto ? userItem.data().foto : '',
                                  //   status: 'invitated',
                                  //   statusInv: 'sended',
                                  //   // status: 'active',
                                  //   // statusInv: 'accepted'
                                  // })

                                  member = {
                                    id: userItem.id,
                                    email,
                                    name: userItem.data().nombre ? userItem.data().nombre : '',
                                    role: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                                    status: 'invitated',
                                    statusInv: 'sended',
                                    avatarURL: userItem.data().foto ? userItem.data().foto : '',
                                    // status: 'active',
                                    // statusInv: 'accepted'
                                  }

                                  // update teams of user belongs
                                  if(userItem.data().teams) {
                                    db.collection('Users').doc(userItem.id).update({
                                      projects: firebase.firestore.FieldValue.arrayUnion(docRef.id),
                                    })
                                    .catch(error => {
                                      console.error(error)
                                    })

                                    db.collection('Users').doc(userItem.id).collection('projects').doc(docRef.id).set({
                                      id: docRef.id,
                                      name: payload.projectName ? payload.projectName : '',
                                      color: payload.projectColor,
                                      isMySites: false,
                                      numberSites: 1,
                                      // sites: site ? site : '',
                                      numberTeamMembers: payload.projectInvitations.length,
                                      owner: payload.owned ? payload.owned : '',
                                      teamMembers: []
                                    })
                                    .catch(error => {
                                      console.error(error)
                                    })

                                    // db.collection('projects').doc(docRef.id).update({
                                    //   teamMembers: firebase.firestore.FieldValue.arrayUnion(userItem.id),
                                    // })
                                    // .catch(error => {
                                    //   console.error(error)
                                    // })

                                    // db.collection('project-details').doc(docRef.id).update({
                                    //   teamMembers: firebase.firestore.FieldValue.arrayUnion(userItem.id),
                                    // })
                                    // .catch(error => {
                                    //   console.error(error)
                                    // })
                                  } else {
                                    db.collection('Users').doc(userItem.id).update({
                                      projects: [docRef.id],
                                    })
                                    .catch(error => {
                                      console.error(error)
                                    })

                                    db.collection('Users').doc(userItem.id).collection('projects').doc(docRef.id).set({
                                      id: docRef.id,
                                      name: payload.projectName ? payload.projectName : '',
                                      color: payload.projectColor,
                                      isMySites: false,
                                      numberSites: 1,
                                      // sites: site ? site : '',
                                      numberTeamMembers: payload.projectInvitations.length,
                                      owner: payload.owned ? payload.owned : '',
                                      teamMembers: []
                                    })
                                    .catch(error => {
                                      console.error(error)
                                    })

                                    // db.collection('projects').doc(docRef.id).update({
                                    //   teamMembers: [userItem.id],
                                    // })
                                    // .catch(error => {
                                    //   console.error(error)
                                    // })

                                    // db.collection('project-details').doc(docRef.id).update({
                                    //   teamMembers: [userItem.id],
                                    // })
                                    // .catch(error => {
                                    //   console.error(error)
                                    // })
                                  }
                                })
                              } else {
                                userID = ''
                                // members.push({
                                //   uid: '',
                                //   email,
                                //   perm: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                                //   status: 'invitated',
                                //   statusInv: 'sended',
                                //   // status: 'active',
                                //   // statusInv: 'accepted'
                                // })

                                member = {
                                  id: '',
                                  email,
                                  name: '',
                                  role: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                                  status: 'invitated',
                                  statusInv: 'sended',
                                  avatarURL: '',
                                  // status: 'active',
                                  // statusInv: 'accepted'
                                }
                              }

                              // send invitation mails
                              const obj = {
                                email,
                                name: payload.owned.nombre ? payload.owned.nombre : payload.owned.email ? payload.owned.email : '',
                                project: payload.projectName,
                                existUser: queryUser.size >= 1 ? true : false
                              }

                              // update seocloud-dcdfb
                              const config = {
                                method: 'POST',
                                url: 'https://us-central1-seocloud-7e24d.cloudfunctions.net/invitationMail',
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                                data: JSON.stringify(obj),
                              }

                              this._vm.axios(config)
                              .then(() => {
                                console.log('email sended')
                              })
                              .catch(error => {
                                console.log(error)
                              })

                              // members projects
                              db.collection('Projects').doc(docRef.id).collection('members').add(member)
                              .catch(error => {
                                console.error(error)
                              })

                              // update array members project
                              db.collection('Projects').doc(docRef.id).get()
                              .then(docProject => {
                                var membersProject = docProject.data().teamMembers ? docProject.data().teamMembers : []
                                var filterMembers = membersProject.filter(eleMember => eleMember.email === member.email)
                                if(!filterMembers.length) {
                                  db.collection('Projects').doc(docRef.id).update({
                                    teamMembers: membersProject.concat(member)
                                  })

                                  if(userID) {
                                    db.collection('Users').doc(userID).collection('projects').doc(docRef.id).update({
                                      teamMembers: membersProject.concat(member)
                                    })
                                  }

                                  db.collection('Users').doc(payload.owned.uid).collection('projects').doc(docRef.id).update({
                                    teamMembers: membersProject.concat(member)
                                  })
                                }
                              })
                              
                              
                              // members project details
                              // db.collection('project-details').doc(docRef.id).collection('members').add(member)
                              // .catch(error => {
                              //   console.error(error)
                              // })
                            })
                            .catch(error => {
                              console.log(error)
                            })
                            // membersEmail.push(email)
                          }
                        })

                        // db.collection('projects').doc(docRef.id).collection('members').doc('resume')
                        // .set({
                        //   members,
                        //   membersEmail,
                        //   owned: payload.owned,
                        //   date: Date.now(),
                        //   uid: 'resume',
                        // })
                        // .catch(error => {
                        //   console.error(error)
                        // })
                      }
                    })
                  }
                } else {
                  this._vm.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'You have already created this site',
                      icon: 'CoffeeIcon',
                      variant: 'warning',
                      text: 'The site was not created',
                    },
                  })

                  // create teamMembers
                  if(payload.projectInvitations.length >= 1) {
                    const members = []
                    // const membersEmail = []
                    let member = ''
                    payload.projectInvitations.forEach(item => {
                      if(item.email.includes('@') && item.email.includes('.')) {
                        const email = item.email.trim()
                        db.collection('Users').where('email', '==', email).get()
                        .then(queryUser => {
                          // verify if user already exist, to add uid
                          var userID = ''
                          if(queryUser.size >= 1) {
                            queryUser.forEach(userItem => {
                              userID = userItem.id
                              // members.push({
                              //   uid: userItem.id,
                              //   email,
                              //   perm: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                              //   imgU: userItem.data().foto ? userItem.data().foto : '',
                              //   status: 'invitated',
                              //   statusInv: 'sended',
                              //   // status: 'active',
                              //   // statusInv: 'accepted'
                              // })

                              member = {
                                id: userItem.id,
                                email,
                                name: userItem.data().nombre ? userItem.data().nombre : '',
                                role: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                                status: 'invitated',
                                statusInv: 'sended',
                                avatarURL: userItem.data().foto ? userItem.data().foto : '',
                                // status: 'active',
                                // statusInv: 'accepted'
                              }

                              // update teams of user belongs
                              if(userItem.data().teams) {
                                db.collection('Users').doc(userItem.id).update({
                                  projects: firebase.firestore.FieldValue.arrayUnion(docRef.id),
                                })
                                .catch(error => {
                                  console.error(error)
                                })

                                db.collection('Users').doc(userItem.id).collection('projects').doc(docRef.id).set({
                                  id: docRef.id,
                                  name: payload.projectName ? payload.projectName : '',
                                  color: payload.projectColor,
                                  isMySites: false,
                                  numberSites: 0,
                                  // sites: site ? site : '',
                                  numberTeamMembers: payload.projectInvitations.length,
                                  owner: payload.owned ? payload.owned : '',
                                  teamMembers: []
                                })
                                .catch(error => {
                                  console.error(error)
                                })

                                // db.collection('projects').doc(docRef.id).update({
                                //   teamMembers: firebase.firestore.FieldValue.arrayUnion(userItem.id),
                                // })
                                // .catch(error => {
                                //   console.error(error)
                                // })

                                // db.collection('project-details').doc(docRef.id).update({
                                //   teamMembers: firebase.firestore.FieldValue.arrayUnion(userItem.id),
                                // })
                                // .catch(error => {
                                //   console.error(error)
                                // })
                              } else {
                                db.collection('Users').doc(userItem.id).update({
                                  projects: [docRef.id],
                                })
                                .catch(error => {
                                  console.error(error)
                                })

                                db.collection('Users').doc(userItem.id).collection('projects').doc(docRef.id).set({
                                  id: docRef.id,
                                  name: payload.projectName ? payload.projectName : '',
                                  color: payload.projectColor,
                                  isMySites: false,
                                  numberSites: 0,
                                  // sites: site ? site : '',
                                  numberTeamMembers: payload.projectInvitations.length,
                                  owner: payload.owned ? payload.owned : '',
                                  teamMembers: []
                                })
                                .catch(error => {
                                  console.error(error)
                                })

                                // db.collection('projects').doc(docRef.id).update({
                                //   teamMembers: [userItem.id],
                                // })
                                // .catch(error => {
                                //   console.error(error)
                                // })

                                // db.collection('project-details').doc(docRef.id).update({
                                //   teamMembers: [userItem.id],
                                // })
                                // .catch(error => {
                                //   console.error(error)
                                // })
                              }
                            })
                          } else {
                            userID = ''
                            // members.push({
                            //   uid: '',
                            //   email,
                            //   perm: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                            //   status: 'invitated',
                            //   statusInv: 'sended',
                            //   // status: 'active',
                            //   // statusInv: 'accepted'
                            // })

                            member = {
                              id: '',
                              email,
                              name: '',
                              role: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                              status: 'invitated',
                              statusInv: 'sended',
                              avatarURL: '',
                              // status: 'active',
                              // statusInv: 'accepted'
                            }
                          }

                          // send invitation mails
                          const obj = {
                            email,
                            name: payload.owned.nombre ? payload.owned.nombre : payload.owned.email ? payload.owned.email : '',
                            project: payload.projectName,
                            existUser: queryUser.size >= 1 ? true : false
                          }

                          // update seocloud-dcdfb
                          const config = {
                            method: 'POST',
                            url: 'https://us-central1-seocloud-7e24d.cloudfunctions.net/invitationMail',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            data: JSON.stringify(obj),
                          }

                          this._vm.axios(config)
                          .then(() => {
                            console.log('email sended')
                          })
                          .catch(error => {
                            console.log(error)
                          })

                          // members projects
                          db.collection('Projects').doc(docRef.id).collection('members').add(member)
                          .catch(error => {
                            console.error(error)
                          })

                          // update array members project
                          db.collection('Projects').doc(docRef.id).get()
                          .then(docProject => {
                            var membersProject = docProject.data().teamMembers ? docProject.data().teamMembers : []
                            var filterMembers = membersProject.filter(eleMember => eleMember.email === member.email)
                            if(!filterMembers.length) {
                              db.collection('Projects').doc(docRef.id).update({
                                teamMembers: membersProject.concat(member)
                              })

                              if(userID) {
                                db.collection('Users').doc(userID).collection('projects').doc(docRef.id).update({
                                  teamMembers: membersProject.concat(member)
                                })
                              }

                              db.collection('Users').doc(payload.owned.uid).collection('projects').doc(docRef.id).update({
                                teamMembers: membersProject.concat(member)
                              })
                            }
                          })
                          
                          
                          // members project details
                          // db.collection('project-details').doc(docRef.id).collection('members').add(member)
                          // .catch(error => {
                          //   console.error(error)
                          // })
                        })
                        .catch(error => {
                          console.log(error)
                        })
                        // membersEmail.push(email)
                      }
                    })

                    // db.collection('projects').doc(docRef.id).collection('members').doc('resume')
                    // .set({
                    //   members,
                    //   membersEmail,
                    //   owned: payload.owned,
                    //   date: Date.now(),
                    //   uid: 'resume',
                    // })
                    // .catch(error => {
                    //   console.error(error)
                    // })
                  }
                }
              })
            })
          }
        } else {
          if(payload.projectInvitations.length >= 1) {
            const members = []
            // const membersEmail = []
            let member = ''
            payload.projectInvitations.forEach(item => {
              if(item.email.includes('@') && item.email.includes('.')) {
                const email = item.email.trim()
                db.collection('Users').where('email', '==', email).get()
                .then(queryUser => {
                  // verify if user already exist, to add uid
                  var userID = ''
                  if(queryUser.size >= 1) {
                    queryUser.forEach(userItem => {
                      userID = userItem.id
                      // members.push({
                      //   uid: userItem.id,
                      //   email,
                      //   perm: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                      //   imgU: userItem.data().foto ? userItem.data().foto : '',
                      //   status: 'invitated',
                      //   statusInv: 'sended',
                      //   // status: 'active',
                      //   // statusInv: 'accepted'
                      // })

                      member = {
                        id: userItem.id,
                        email,
                        name: userItem.data().nombre ? userItem.data().nombre : '',
                        role: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                        status: 'invitated',
                        statusInv: 'sended',
                        avatarURL: userItem.data().foto ? userItem.data().foto : '',
                        // status: 'active',
                        // statusInv: 'accepted'
                      }

                      // update teams of user belongs
                      if(userItem.data().teams) {
                        db.collection('Users').doc(userItem.id).update({
                          projects: firebase.firestore.FieldValue.arrayUnion(docRef.id),
                        })
                        .catch(error => {
                          console.error(error)
                        })

                        db.collection('Users').doc(userItem.id).collection('projects').doc(docRef.id).set({
                          id: docRef.id,
                          name: payload.projectName ? payload.projectName : '',
                          color: payload.projectColor,
                          isMySites: false,
                          numberSites: 0,
                          // sites: site ? site : '',
                          numberTeamMembers: payload.projectInvitations.length,
                          owner: payload.owned ? payload.owned : '',
                          teamMembers: []
                        })
                        .catch(error => {
                          console.error(error)
                        })

                        // db.collection('projects').doc(docRef.id).update({
                        //   teamMembers: firebase.firestore.FieldValue.arrayUnion(userItem.id),
                        // })
                        // .catch(error => {
                        //   console.error(error)
                        // })

                        // db.collection('project-details').doc(docRef.id).update({
                        //   teamMembers: firebase.firestore.FieldValue.arrayUnion(userItem.id),
                        // })
                        // .catch(error => {
                        //   console.error(error)
                        // })
                      } else {
                        db.collection('Users').doc(userItem.id).update({
                          projects: [docRef.id],
                        })
                        .catch(error => {
                          console.error(error)
                        })

                        db.collection('Users').doc(userItem.id).collection('projects').doc(docRef.id).set({
                          id: docRef.id,
                          name: payload.projectName ? payload.projectName : '',
                          color: payload.projectColor,
                          isMySites: false,
                          numberSites: 0,
                          // sites: site ? site : '',
                          numberTeamMembers: payload.projectInvitations.length,
                          owner: payload.owned ? payload.owned : '',
                          teamMembers: []
                        })
                        .catch(error => {
                          console.error(error)
                        })

                        // db.collection('projects').doc(docRef.id).update({
                        //   teamMembers: [userItem.id],
                        // })
                        // .catch(error => {
                        //   console.error(error)
                        // })

                        // db.collection('project-details').doc(docRef.id).update({
                        //   teamMembers: [userItem.id],
                        // })
                        // .catch(error => {
                        //   console.error(error)
                        // })
                      }
                    })
                  } else {
                    userID = ''
                    // members.push({
                    //   uid: '',
                    //   email,
                    //   perm: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                    //   status: 'invitated',
                    //   statusInv: 'sended',
                    //   // status: 'active',
                    //   // statusInv: 'accepted'
                    // })

                    member = {
                      id: '',
                      email,
                      name: '',
                      role: item.permission === 'Editor' || item.permission === 'edit' || (item.permission.title && item.permission.title === 'Editor') ? 'edit' : item.permission === 'Viewer' || item.permission === 'view' || (item.permission.title && item.permission.title === 'Viewer') ? 'view' : item.permission === 'Admin/editor' || item.permission === 'admin' || (item.permission.title && item.permission.title === 'Admin/editor') ? 'admin' : 'view',
                      status: 'invitated',
                      statusInv: 'sended',
                      avatarURL: '',
                      // status: 'active',
                      // statusInv: 'accepted'
                    }
                  }

                  // send invitation mails
                  const obj = {
                    email,
                    name: payload.owned.nombre ? payload.owned.nombre : payload.owned.email ? payload.owned.email : '',
                    project: payload.projectName,
                    existUser: queryUser.size >= 1 ? true : false
                  }

                  // update seocloud-dcdfb
                  const config = {
                    method: 'POST',
                    url: 'https://us-central1-seocloud-7e24d.cloudfunctions.net/invitationMail',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    data: JSON.stringify(obj),
                  }

                  this._vm.axios(config)
                  .then(() => {
                    console.log('email sended')
                  })
                  .catch(error => {
                    console.log(error)
                  })

                  // members projects
                  db.collection('Projects').doc(docRef.id).collection('members').add(member)
                  .catch(error => {
                    console.error(error)
                  })

                  // update array members project
                  db.collection('Projects').doc(docRef.id).get()
                  .then(docProject => {
                    var membersProject = docProject.data().teamMembers ? docProject.data().teamMembers : []
                    var filterMembers = membersProject.filter(eleMember => eleMember.email === member.email)
                    if(!filterMembers.length) {
                      db.collection('Projects').doc(docRef.id).update({
                        teamMembers: membersProject.concat(member)
                      })

                      if(userID) {
                        db.collection('Users').doc(userID).collection('projects').doc(docRef.id).update({
                          teamMembers: membersProject.concat(member)
                        })
                      }

                      db.collection('Users').doc(payload.owned.uid).collection('projects').doc(docRef.id).update({
                        teamMembers: membersProject.concat(member)
                      })
                    }
                  })
                  
                  
                  // members project details
                  // db.collection('project-details').doc(docRef.id).collection('members').add(member)
                  // .catch(error => {
                  //   console.error(error)
                  // })
                })
                .catch(error => {
                  console.log(error)
                })
                // membersEmail.push(email)
              }
            })

            // db.collection('projects').doc(docRef.id).collection('members').doc('resume')
            // .set({
            //   members,
            //   membersEmail,
            //   owned: payload.owned,
            //   date: Date.now(),
            //   uid: 'resume',
            // })
            // .catch(error => {
            //   console.error(error)
            // })
          }
        }
        // router.push({name:'projects'})
        // router.push({ name: 'new-site', params: { idPro: { uid: docRef.id, title: payload.projectName, icon: payload.projectColor }, urlSite: site, nameSite: payload.siteName } })
        router.push({ name: 'project-sites', params: {id: docRef.id, name: payload.projectName, own: 'me'} })
      })
      .catch(error => {
        console.error(error)
      })
    },
    cerrarSesion({ commit }) {
      // cerrar sesión
      // console.log('logout')
      localStorage.removeItem('ll')
      localStorage.removeItem('lastNotificationTimeSend')
      auth.signOut()
      localStorage.clear()
      localStorage.setItem('userData', JSON.stringify({ teams: 0 }))
      commit('nuevoUsuario', null)
      localStorage.removeItem('usAb')
      localStorage.removeItem('logActy')
      window.location.href = '/login'
    },
    cerrarSesionShared({ commit }) {
      // cerrar sesión
      // console.log('logout')
      auth.signOut()
      localStorage.removeItem('lastNotificationTimeSend')
      commit('nuevoUsuario', null)
      // localStorage.removeItem('usAb')
      // localStorage.removeItem('logActy')
    }
  },
  getters: {
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
