export default [
  // {
  //   path: '/my-sites',
  //   name: 'access-control',
  //   component: () => import('@/views/extensions/acl/AccessControl.vue'),
  //   meta: {
  //     resource: 'ACL',
  //     action: 'read',
  //     pageTitle: 'My sites',
  //     title: 'My sites - SEOcloud',
  //   },
  // },
]
