import { Ability } from '@casl/ability'
import { initialAbility } from './config'
import { firebase, auth } from '@/firebase'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
// const user = auth.currentUser
// let us = ''
// auth.onAuthStateChanged((userdata) => {
//     us = userdata
// })
// const user = firebase.auth().currentUser;
// if (user !== null) {
//     user.providerData.forEach((profile) => {
//       console.log("Sign-in provider: " + profile.providerId);
//       console.log("  Provider-specific UID: " + profile.uid);
//       console.log("  Name: " + profile.displayName);
//       console.log("  Email: " + profile.email);
//       console.log("  Photo URL: " + profile.photoURL);
//     });
//   }
// console.log(user,us)
// const existingAbility = user ? user.ability : null
const userDataAbility = JSON.parse(localStorage.getItem('usAb'))
// console.log(userDataAbility)
// console.log(user.ability)
// const userData = {
//     ability: [
//         {
//             action: "manage",
//             subject: "all"
//         }
//     ]
// }
const existingAbility = userDataAbility ? userDataAbility.ability : null

export default new Ability(existingAbility || initialAbility)
