export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/shared-screenshots/:id?',
    redirect: to => {
      const baseUrl = `https://screenshots.seocloud.ai/${to.params.id}`
      const queryString = Object.keys(to.query).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(to.query[key])}`).join('&')
      window.location.href = `${baseUrl}?${queryString}`
      return '/redirecting' // not important since redirecting
    },
  },
  {
    path: '/requests',
    name: 'requests',
    component: () => import('@/views/pages/authentication/Requests.vue'),
    meta: {
      layout: 'full',
      // resource: 'Auth',
      // redirectIfLoggedIn: true,
      resource: 'Auth',
      action: 'read',
      pageTitle: 'Csv To JSON',
      title: 'Csv To JSON - SEOcloud',
      contentClass: 'ecommerce-application',
      title: 'SEOcloud',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      title: 'Login to your SEOcloud account',
    },
  },
  {
    path: '/sign-up',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      title: 'Register - SEOcloud',
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      title: 'Forgot Password - SEOcloud',
    },
  },
  {
    path: '/password/reset',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/pages/miscellaneous/Contact.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Contact',
      title: 'Contact - SEOcloud',
      breadcrumb: [
        {
          text: 'Contact',
          active: true,
        },
      ],
    },
  },
  {
    path: '/get-help',
    name: 'get-help',
    component: () => import('@/views/pages/miscellaneous/GetHelp.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Get help',
      title: 'Get help - SEOcloud',
      breadcrumb: [
        {
          text: 'Get help',
          active: true,
        },
      ],
    },
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import('@/views/pages/miscellaneous/TermsService.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Terms of Service',
      title: 'Terms of Service - SEOcloud',
      breadcrumb: [
        {
          text: 'Terms of Service',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/success-payment',
    name: 'success-payment',
    component: () => import('@/views/pages/miscellaneous/SuccessPayment.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/cancel-payment',
    name: 'cancel-payment',
    component: () => import('@/views/pages/miscellaneous/CancelPayment.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/my-profile',
    name: 'pages-account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'My profile',
      title: 'My profile - SEOcloud',
      breadcrumb: [
        {
          text: 'My profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/faq',
    name: 'pages-faq',
    component: () => import('@/views/pages/faq/Faq.vue'),
    meta: {
      pageTitle: 'FAQ',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'FAQ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/share',
    name: 'shared-site',
    component: () => import('@/views/apps/sites/sites-edit/SharedSite.vue'),
    meta: {
      pageTitle: 'Shared site',
      resource: 'Auth',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/contact-invited',
    name: 'contact-invited',
    component: () => import('@/views/pages/miscellaneous/ContactInvited.vue'),
    meta: {
      resource: 'Auth',
      contentClass: 'ecommerce-application',
      pageTitle: 'Contact',
      title: 'Contact - SEOcloud',
      breadcrumb: [
        {
          text: 'Contact',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/settings',
  //   name: 'pages-settings-quickbooks',
  //   component: () => import('@/views/pages/account-setting/SettingsQuickbooks.vue'),
  //   meta: {
  //     pageTitle: 'settings',
  //     breadcrumb: [
  //       {
  //         text: 'Settings',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // *===============================================---*
  // *--------- Database -------------------------------------------*
  // *===============================================---*
  // {
  //   path: '/conf/database/csv-to-json',
  //   name: 'csv-to-json',
  //   component: () => import('@/views/pages/database-conf/CsvToJson.vue'),
  //   meta: {
  //     resource: 'ACL',
  //     action: 'read',
  //     pageTitle: 'Csv To JSON',
  //     title: 'Csv To JSON - SEOcloud',
  //     contentClass: 'ecommerce-application',
  //   },
  // },
]
